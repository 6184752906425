export const messageCenterConstants = {
    GET_THREAD_REQUEST: 'GET_THRED_REQUEST',
    GET_THREAD_SUCCESS: 'GET_THRED_SUCCESS',
    GET_THREAD_FAILURE: 'GET_THRED_FAILURE',

    GET_MESSAGE_REQUEST: 'GET_MESSAGE_REQUEST',
    GET_MESSAGE_SUCCESS: 'GET_MESSAGE_SUCCESS',
    GET_MESSAGE_FAILURE: 'GET_MESSAGE_FAILURE',

    GET_RECENT_MESSAGE_REQUEST: 'MESSAGE_CENTER_GET_RECENT_MESSAGE_REQUEST',
    GET_RECENT_MESSAGE_SUCCESS: 'MESSAGE_CENTER_GET_RECENT_MESSAGE_SUCCESS',
    GET_RECENT_MESSAGE_FAILURE: 'MESSAGE_CENTER_GET_RECENT_MESSAGE_FAILURE',


    CREATE_THREAD_REQUEST: 'CREATE_THREAD_REQUEST',
    CREATE_THREAD_SUCCESS: 'CREATE_THREAD_SUCCESS',
    CREATE_THREAD_FAILURE: 'CREATE_THREAD_FAILURE',

    CREATE_MESSAGE_REQUEST: 'CREATE_MESSAGE_REQUEST',
    CREATE_MESSAGE_SUCCESS: 'CREATE_MESSAGE_SUCCESS',
    CREATE_MESSAGE_FAILURE: 'CREATE_MESSAGE_FAILURE',

    SELECT_THREAD: 'SELECT_THREAD',
    SET_TYPED_MESSAGE : 'SET_TYPED_MESSAGE',

    CREATE_FILE_ATTACHEMENT_REQUEST: 'CREATE_FILE_ATTACHEMENT_REQUEST',
    CREATE_FILE_ATTACHEMENT_SUCCESS: 'CREATE_FILE_ATTACHEMENT_SUCCESS',
    CREATE_FILE_ATTACHEMENT_FAILURE: 'CREATE_FILE_ATTACHEMENT_FAILURE',

    GET_FILE_ATTACHEMENT_REQUEST: 'GET_FILE_ATTACHEMENT_REQUEST',
    GET_FILE_ATTACHEMENT_SUCCESS: 'GET_FILE_ATTACHEMENT_SUCCESS',
    GET_FILE_ATTACHEMENT_FAILURE: 'GET_FILE_ATTACHEMENT_FAILURE',

    DOWNLOAD_FILE_ATTACHEMENT_REQUEST: 'DOWNLOAD_FILE_ATTACHEMENT_REQUEST',
    DOWNLOAD_FILE_ATTACHEMENT_SUCCESS: 'DOWNLOAD_FILE_ATTACHEMENT_SUCCESS',
    DOWNLOAD_FILE_ATTACHEMENT_FAILURE: 'DOWNLOAD_FILE_ATTACHEMENT_FAILURE',

    GET_SHARED_FILE_REQUEST: 'GET_SHARED_FILE_REQUEST',
    GET_SHARED_FILE_SUCCESS: 'GET_SHARED_FILE_SUCCESS',
    GET_SHARED_FILE_FAILURE: 'GET_SHARED_FILE_FAILURE',

    MESSAGE_CENTER_CLEAR: 'MESSAGE_CENTER_CLEAR_PROPS',

};
