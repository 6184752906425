export const companyConstants = {
    GET_ALL_COMPANIES_REQUEST: 'GET_ALL_COMPANIES_REQUEST',
    GET_ALL_COMPANIES_SUCCESS: 'GET_ALL_COMPANIES_SUCCESS',
    GET_ALL_COMPANIES_FAILURE: 'GET_ALL_COMPANIES_FAILURE',

    GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
    GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
    GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

    CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST',
    CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
    CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',

    UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
    UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
    UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',

    REMOVE_COMPANY_REQUEST: 'REMOVE_COMPANY_REQUEST',
    REMOVE_COMPANY_SUCCESS: 'REMOVE_COMPANY_SUCCESS',
    REMOVE_COMPANY_FAILURE: 'REMOVE_COMPANY_FAILURE',

    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',

    GET_ALL_COMPANIES_FILTER_REQUEST: 'GET_ALL_COMPANIES_FILTER_REQUEST',
    GET_ALL_COMPANIES_FILTER_SUCCESS: 'GET_ALL_COMPANIES_FILTER_SUCCESS',
    GET_ALL_COMPANIES_FILTER_FAILURE: 'GET_ALL_COMPANIES_FILTER_FAILURE',

    GET_ALL_IMPORT_EMPLOYEE_REQUEST: 'GET_ALL_IMPORT_EMPLOYEE_REQUEST',
    GET_ALL_IMPORT_EMPLOYEE_SUCCESS: 'GET_ALL_IMPORT_EMPLOYEE_SUCCESS',
    GET_ALL_IMPORT_EMPLOYEE_FAILURE: 'GET_ALL_IMPORT_EMPLOYEE_FAILURE',

    CLEAR_IMPORT_EMPLOYEE_ERRORS: 'CLEAR_IMPORT_EMPLOYEE_ERRORS',

    CLEAR_COMPANY_ERRORS: 'CLEAR_COMPANY_ERRORS',
};
