import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as modal } from 'redux-modal';


import { auth } from "./auth-reducer";
import { lookups } from "./lookups-reducer";
import { carePlans } from "./care-plans-reducer";
import { dashboard } from './dashboard-reducer';
import { company } from './company-reducer';
import { notices } from './notices-reducer';
import { members } from './members-reducers';
import { careTeam } from './care-team-reducer';
import { notifications } from './notifications-reducer';
import { messageCenter } from './message-center-reducer';
import { progressNotes } from './progress-notes-reducer'
import { medicalRecords } from './medical-records-reducer';
import { logging } from './logging-reducer';
import { resources } from './resources-reducer';
import { tasks } from './task-reducers';
import { callCenter } from './call-center-reducer';
import { appointment } from './appointment-reducer';
import { patient } from './patient-reducer';
import { attachment } from './attachment-reducer';
import { event } from './event-reducer';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    modal,
    auth,
    lookups,
    carePlans,
    progressNotes,
    dashboard,
    company,
    notices,
    members,
    careTeam,
    notifications,
    messageCenter,
    medicalRecords,
    logging,
    resources,
    tasks,
    callCenter,
    appointment,
    patient,
    attachment,
    event,
});

export default rootReducer;
