import { taskConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';

export function tasks(state = {}, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return {};

        case taskConstants.GET_ALL_REQUEST:
            return {
                ...omit(state, ['get_all_failure', 'get_all_success'] ),
                get_all_request: true
            };
        case taskConstants.GET_ALL_SUCCESS:
            return {
                ...omit(state, ['get_all_request', 'get_all_failure'] ),
                tasks: action.response,
                get_all_success : true,
            }
        case taskConstants.GET_ALL_FAILURE:
            return {
                ...omit(state, ['get_all_request', 'get_all_success'] ),
                create_error: action.response,
                get_all_failure : true
            }

        case taskConstants.TOOGLE_COMPLETE_REQUEST:
            return {
                ...omit(state, ['toggle_task_success', 'toggle_task_failure'] ),
                toggle_task_request: true,
                [`task_toggle_status_${action.task_id}`] : 'request'
            };
        case taskConstants.TOOGLE_COMPLETE_SUCCESS:
            return {
                ...omit(state, ['toggle_task_request', 'toggle_task_failure', `task_toggle_status_${action.task_id}` ] ),
                toogled_resource: action.response,
                toggle_task_success : true,
            }
        case taskConstants.TOOGLE_COMPLETE_FAILURE:
            return {
                ...omit(state, ['toggle_task_request', 'toggle_task_success', `task_toggle_status_${action.task_id}` ] ),
                create_error: action.response,
                toggle_task_failure : true,
            }

        case taskConstants.GET_CALANDAR_REQUEST:
            return {
                ...omit(state, ['get_calendar_success', 'get_calendar_failure'] ),
                get_calendar_request: true
            };
        case taskConstants.GET_CALANDAR_SUCCESS:
            return {
                ...omit(state, ['get_calendar_request', 'get_calendar_failure'] ),
                calander: action.response,
                get_calendar_success : true,
            }
        case taskConstants.GET_CALANDAR_FAILURE:
            return {
                ...omit(state, ['get_calendar_request', 'get_calendar_success'] ),
                get_calander_error: action.response,
                get_calendar_failure : true,
            }

        case taskConstants.CREATE_REQUEST:
            return {
                ...omit(state, ['create_failure', 'create_success'] ),
                create_request: true
            };
        case taskConstants.CREATE_SUCCESS:
            return {
                ...omit(state, ['create_request', 'create_failure'] ),
                created_task: action.response,
                create_success : true,
            }
        case taskConstants.CREATE_FAILURE:
            return {
                ...omit(state, ['create_request', 'create_success'] ),
                created_error: action.response,
                create_failure : true
            }

        case taskConstants.UPDATE_REQUEST:
            return {
                ...omit(state, ['update_failure', 'update_success'] ),
                update_request: true
            };
        case taskConstants.UPDATE_SUCCESS:
            return {
                ...omit(state, ['update_request', 'update_failure'] ),
                updated_task: action.response,
                update_success : true,
            }
        case taskConstants.UPDATE_FAILURE:
            return {
                ...omit(state, ['update_request', 'update_success'] ),
                created_error: action.response,
                update_failure : true
            }


        case taskConstants.REMOVE_REQUEST:
            return {
                ...state,
                task_remove_request: true
            };
        case taskConstants.REMOVE_SUCCESS:
            return {
                ...omit(state, 'task_remove_request'),
                removed_task: true
            }
        case taskConstants.REMOVE_FAILURE:
            return {
                ...omit(state, 'task_remove_request'),
            }


        case taskConstants.ARCHIVE_REQUEST:
            return {
                ...omit(state, ['created_error'] ),
                archive_request: true
            };
        case taskConstants.ARCHIVE_SUCCESS:
            return {
                ...omit(state, ['archive_request'] ),
                archive_response: action.response
            }
        case taskConstants.ARCHIVE_FAILURE:
            return {
                ...omit(state, ['archive_request'] ),
                created_error: action.response,
            }

        case taskConstants.DOWNLOAD_ATTACHEMENT_REQUEST:
            return {
                ...state,
                download_file_attachement_request: true,
            };
        case taskConstants.DOWNLOAD_ATTACHEMENT_SUCCESS:
            return {
                ...omit(state, 'download_file_attachement_request'),
                downloaded_file: action.response
            };
        case taskConstants.DOWNLOAD_ATTACHEMENT_FAILURE:
            return {
                ...omit(state, 'download_file_attachement_request'),
                created_errors: action.response
            };

        case taskConstants.CLEAR_PROPS:
            return {
                ...omit(state, [action.payload]),
            }


        default:
            return state;
    }
}






