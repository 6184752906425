import { noticesConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';



const initialState = {};

export function notices(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;
            
        case noticesConstants.CLEAR_NOTICES_PROPS:
            return {
                ...omit(state, [action.payload])
            }

            case noticesConstants.GET_ALL_NOTICES_REQUEST:
            return {
                ...state,
                get_all_notices_request: true
            };
        case noticesConstants.GET_ALL_NOTICES_SUCCESS:
            return {
                ...omit(state, 'get_all_notices_request'),
                notices: action.response
            }
        case noticesConstants.GET_ALL_NOTICES_FAILURE:
            return {
                ...omit(state, 'get_all_notices_request'),
            }

        case noticesConstants.GET_NOTICE_REQUEST:
            return {
                ...state,
                get_notice_request: true
            };
        case noticesConstants.GET_NOTICE_SUCCESS:
            return {
                ...omit(state, 'get_notice_request'),
                selected_notice: action.response
            }
        case noticesConstants.GET_NOTICE_FAILURE:
            return {
                ...omit(state, 'get_notice_request'),
            }

        case noticesConstants.UPDATE_NOTICES_REQUEST:
            return {
                ...state,
                update_notice_request: true
            };
        case noticesConstants.UPDATE_NOTICES_SUCCESS:
            return {
                ...omit(state, 'update_notice_request'),
                updated_notice: action.response
            }
        case noticesConstants.UPDATE_NOTICES_FAILURE:
            return {
                ...omit(state, 'update_notice_request'),
                create_error: action.response
            }

        case noticesConstants.ADD_NOTICE_REQUEST:
            return {
                ...state,
                create_notice_request: true
            };
        case noticesConstants.ADD_NOTICE_SUCCESS:
            return {
                ...omit(state, 'create_notice_request'),
                created_company: action.response
            }
        case noticesConstants.ADD_NOTICE_FAILURE:
            return {
                ...omit(state, 'create_notice_request'),
                create_error: action.response
            }

        case noticesConstants.REMOVE_NOTICE_REQUEST:
            return {
                ...state,
                remove_notice_request: true
            };
        case noticesConstants.REMOVE_NOTICE_SUCCESS:
            return {
                ...omit(state, 'remove_notice_request'),
                removed_notice: action.response
            }
        case noticesConstants.REMOVE_NOTICE_FAILURE:
            return {
                ...omit(state, 'remove_notice_request'),
            }


        default:
            return state;
    }
}
