import { resourcesConstants, authConstants } from 'js/constants';
import { omit, map } from 'lodash';

export function resources(state = {}, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return {};

        case resourcesConstants.GET_ALL_COMPANIES_REQUEST:
            return {
                ...state,
                get_all_request: true
            };
        case resourcesConstants.GET_ALL_COMPANIES_SUCCESS:
            return {
                ...omit(state, 'get_all_request'),
                companies: action.response
            }
        case resourcesConstants.GET_ALL_COMPANIES_FAILURE:
            return {
                ...omit(state, 'get_all_request'),
                create_error: action.response
            }

        case resourcesConstants.GET_ALL_RESOURCE_REQUEST:
            return {
                ...state,
                get_resource_all_request: true
            };
        case resourcesConstants.GET_ALL_RESOURCE_SUCCESS:
            return {
                ...omit(state, 'get_resource_all_request'),
                resources: action.response
            }
        case resourcesConstants.GET_ALL_RESOURCE_FAILURE:
            return {
                ...omit(state, 'get_all_request'),
                create_error: action.response
            }

        case resourcesConstants.GET_RESOURCE_REQUEST:
            return {
                ...state,
                get_resource_request: true
            };
        case resourcesConstants.GET_RESOURCE_SUCCESS:
            return {
                ...omit(state, 'get_resource_request'),
                selected_resource: action.response
            }
        case resourcesConstants.GET_RESOURCE_FAILURE:
            return {
                ...omit(state, 'get_resource_request'),
                create_error: action.response
            }

        case resourcesConstants.CREATE_RESOURCE_REQUEST:
            return {
                ...state,
                create_resource_request: true
            };
        case resourcesConstants.CREATE_RESOURCE_SUCCESS:
            return {
                ...omit(state, 'create_resource_request'),
                created_resource: action.response
            }
        case resourcesConstants.CREATE_RESOURCE_FAILURE:
            return {
                ...omit(state, 'create_resource_request'),
                create_error: action.response
            }


        case resourcesConstants.UPDATE_RESOURCE_REQUEST:
            return {
                ...state,
                update_resource_request: true
            };
        case resourcesConstants.UPDATE_RESOURCE_SUCCESS:
            return {
                ...omit(state, 'update_resource_request'),
                updates_resource: action.response,
                resources: {
                    ...state.resources,
                    data: ( state.resources.data ? state.resources.data : [] ).map( (item) => ( item.resource_id === action.response.resource_id ? action.response : item )),
                }
            }
        case resourcesConstants.UPDATE_RESOURCE_FAILURE:
            return {
                ...omit(state, 'update_resource_request'),
                create_error: action.response
            }

        case resourcesConstants.REMOVE_SELECTED_RESOURCE_REQUEST:
            return {
                ...state,
                delete_resource_request: true
            };
        case resourcesConstants.REMOVE_SELECTED_RESOURCE_SUCCESS:
            return {
                ...omit(state, 'delete_resource_request'),
                deleted_resource: action.response
            }
        case resourcesConstants.REMOVE_SELECTED_RESOURCE_FAILURE:
            return {
                ...omit(state, 'delete_resource_request'),
                create_error: action.response
            }

        case resourcesConstants.TOOGLE_FAVORITE_RESOURCE_REQUEST:
            return {
                ...state,
                toogle_favorite_resource_request: true
            };
        case resourcesConstants.TOOGLE_FAVORITE_RESOURCE_SUCCESS:
            return {
                ...omit(state, 'toogle_favorite_resource_request'),
                toogled_resource: action.response
            }
        case resourcesConstants.TOOGLE_FAVORITE_RESOURCE_FAILURE:
            return {
                ...omit(state, 'toogle_favorite_resource_request'),
                create_error: action.response
            }


        case resourcesConstants.CLEAR_RESOURCE_PROPS:
            return {
                ...omit(state, [action.payload]),
            }


        default:
            return state;
    }
}






