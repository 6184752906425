export const membersConstants = {
    GET_PATIENT_LIST_REQUEST: 'GET_PATIENT_LIST_REQUEST',
    GET_PATIENT_LIST_SUCCESS: 'GET_PATIENT_LIST_SUCCESS',
    GET_PATIENT_LIST_FAILURE: 'GET_PATIENT_LIST_FAILURE',

    PATIENT_UPDATE_REQUEST: 'PATIENT_UPDATE_REQUEST',
    PATIENT_UPDATE_SUCCESS: 'PATIENT_UPDATE_SUCCESS',
    PATIENT_UPDATE_FAILURE: 'PATIENT_UPDATE_FAILURE',

    PATIENT_GET_REQUEST: 'PATIENT_GET_REQUEST',
    PATIENT_GET_SUCCESS: 'PATIENT_GET_SUCCESS',
    PATIENT_GET_FAILURE: 'PATIENT_GET_FAILURE',

    GET_MEDICAL_CONDITIONS_REQUEST: 'GET_MEDICAL_CONDITIONS_REQUEST',
    GET_MEDICAL_CONDITIONS_SUCCESS: 'GET_MEDICAL_CONDITIONS_SUCCESS',
    GET_MEDICAL_CONDITIONS_FAILURE: 'GET_MEDICAL_CONDITIONS_FAILURE',

    GET_SELECTED_MEDICAL_CONDITIONS_REQUEST: 'GET_SELECTED_MEDICAL_CONDITIONS_REQUEST',
    GET_SELECTED_MEDICAL_CONDITIONS_SUCCESS: 'GET_SELECTED_MEDICAL_CONDITIONS_SUCCESS',
    GET_SELECTED_MEDICAL_CONDITIONS_FAILURE: 'GET_SELECTED_MEDICAL_CONDITIONS_FAILURE',

    CREATE_MEDICAL_CONDITIONS_REQUEST: 'CREATE_MEDICAL_CONDITIONS_REQUEST',
    CREATE_MEDICAL_CONDITIONS_SUCCESS: 'CREATE_MEDICAL_CONDITIONS_SUCCESS',
    CREATE_MEDICAL_CONDITIONS_FAILURE: 'CREATE_MEDICAL_CONDITIONS_FAILURE',

    UPDATE_MEDICAL_CONDITIONS_REQUEST: 'UPDATE_MEDICAL_CONDITIONS_REQUEST',
    UPDATE_MEDICAL_CONDITIONS_SUCCESS: 'UPDATE_MEDICAL_CONDITIONS_SUCCESS',
    UPDATE_MEDICAL_CONDITIONS_FAILURE: 'UPDATE_MEDICAL_CONDITIONS_FAILURE',

    DELETE_MEDICAL_CONDITIONS_REQUEST: 'DELETE_MEDICAL_CONDITIONS_REQUEST',
    DELETE_MEDICAL_CONDITIONS_SUCCESS: 'DELETE_GET_MEDICAL_CONDITIONS_SUCCESS',
    DELETE_MEDICAL_CONDITIONS_FAILURE: 'DELETE_GET_MEDICAL_CONDITIONS_FAILURE',

    GET_PATIENTS_CARE_TEAM_REQUEST: 'GET_PATIENTS_CARE_TEAM_REQUEST',
    GET_PATIENTS_CARE_TEAM_SUCCESS: 'GET_PATIENTS_CARE_TEAM_SUCCESS',
    GET_PATIENTS_CARE_TEAM_FAILURE: 'GET_PATIENTS_CARE_TEAM_FAILURE',

    GET_SURGICAL_OPERATIONS_REQUEST: 'GET_SURGICAL_OPERATIONS_REQUEST',
    GET_SURGICAL_OPERATIONS_SUCCESS: 'GET_SURGICAL_OPERATIONS_SUCCESS',
    GET_SURGICAL_OPERATIONS_FAILURE: 'GET_SURGICAL_OPERATIONS_FAILURE',

    GET_SELECTED_SURGICAL_OPERATION_REQUEST: 'GET_SELECTED_SURGICAL_OPERATION_REQUEST',
    GET_SELECTED_SURGICAL_OPERATION_SUCCESS: 'GET_SELECTED_SURGICAL_OPERATION_SUCCESS',
    GET_SELECTED_SURGICAL_OPERATION_FAILURE: 'GET_SELECTED_SURGICAL_OPERATION_FAILURE',

    CREATE_SURGICAL_OPERATIONS_REQUEST: 'CREATE_SURGICAL_OPERATIONS_REQUEST',
    CREATE_SURGICAL_OPERATIONS_SUCCESS: 'CREATE_SURGICAL_OPERATIONS_SUCCESS',
    CREATE_SURGICAL_OPERATIONS_FAILURE: 'CREATE_SURGICAL_OPERATIONS_FAILURE',

    UPDATE_SURGICAL_OPERATIONS_REQUEST: 'UPDATE_SURGICAL_OPERATIONS_REQUEST',
    UPDATE_SURGICAL_OPERATIONS_SUCCESS: 'UPDATE_SURGICAL_OPERATIONS_SUCCESS',
    UPDATE_SURGICAL_OPERATIONS_FAILURE: 'UPDATE_SURGICAL_OPERATIONS_FAILURE',

    DELETE_SURGICAL_OPERATION_REQUEST: 'DELETE_SURGICAL_OPERATION_REQUEST',
    DELETE_SURGICAL_OPERATION_SUCCESS: 'DELETE_SURGICAL_OPERATION_SUCCESS',
    DELETE_SURGICAL_OPERATION_FAILURE: 'DELETE_SURGICAL_OPERATION_FAILURE',

    GET_PATIENT_ALLERGIC_SUBSTANCES_REQUEST: 'GET_PATIENT_ALLERGIC_SUBSTANCES_REQUEST',
    GET_PATIENT_ALLERGIC_SUBSTANCES_SUCCESS: 'GET_PATIENT_ALLERGIC_SUBSTANCES_SUCCESS',
    GET_PATIENT_ALLERGIC_SUBSTANCES_FAILURE: 'GET_PATIENT_ALLERGIC_SUBSTANCES_FAILURE',


    ADD_NEW_MEDICATION_REQUEST: 'ADD_NEW_MEDICATION_REQUEST',
    ADD_NEW_MEDICATION_SUCCESS: 'ADD_NEW_MEDICATION_SUCCESS',
    ADD_NEW_MEDICATION_FAILURE: 'ADD_NEW_MEDICATION_FAILURE',

    REMOVE_MEDICATION_REQUEST: 'REMOVE_MEDICATION_REQUEST',
    REMOVE_MEDICATION_SUCCESS: 'REMOVE_MEDICATION_SUCCESS',
    REMOVE_MEDICATION_FAILURE: 'REMOVE_MEDICATION_FAILURE',

    UPDATE_MEDICINE_REQUEST: 'UPDATE_MEDICINE_REQUEST',
    UPDATE_MEDICINE_SUCCESS: 'UPDATE_MEDICINE_SUCCESS',
    UPDATE_MEDICINE_FAILURE: 'UPDATE_MEDICINE_FAILURE',

    GET_SELECTED_MEDICINE_REQUEST: 'GET_SELECTED_MEDICINE_REQUEST',
    GET_SELECTED_MEDICINE_SUCCESS: 'GET_SELECTED_MEDICINE_SUCCESS',
    GET_SELECTED_MEDICINE_FAILURE: 'GET_SELECTED_MEDICINE_FAILURE',

    GET_MEDICATIONS_REQUEST: 'GET_MEDICATIONS_REQUEST',
    GET_MEDICATIONS_SUCCESS: 'GET_MEDICATIONS_SUCCESS',
    GET_MEDICATIONS_FAILURE: 'GET_MEDICATIONS_FAILURE',

    GET_MEDICINES_REQUEST: 'GET_MEDICINES_REQUEST',
    GET_MEDICINES_SUCCESS: 'GET_MEDICINES_SUCCESS',
    GET_MEDICINES_FAILURE: 'GET_MEDICINES_FAILURE',

    MEDICAL_HISTORY_STEP_ONE_CREATE_REQUEST: 'MEDICAL_HISTORY_STEP_ONE_CREATE_REQUEST',
    MEDICAL_HISTORY_STEP_ONE_CREATE_SUCCESS: 'MEDICAL_HISTORY_STEP_ONE_CREATE_SUCCESS',
    MEDICAL_HISTORY_STEP_ONE_CREATE_FAILURE: 'MEDICAL_HISTORY_STEP_ONE_CREATE_FAILURE',

    MEDICAL_HISTORY_STEP_TWO_CREATE_REQUEST: 'MEDICAL_HISTORY_STEP_TWO_CREATE_REQUEST',
    MEDICAL_HISTORY_STEP_TWO_CREATE_SUCCESS: 'MEDICAL_HISTORY_STEP_TWO_CREATE_SUCCESS',
    MEDICAL_HISTORY_STEP_TWO_CREATE_FAILURE: 'MEDICAL_HISTORY_STEP_TWO_CREATE_FAILURE',

    STEP_THREE_CREATE_REQUEST: 'MEDICAL_HISTORY_STEP_THREE_CREATE_REQUEST',
    STEP_THREE_CREATE_SUCCESS: 'MEDICAL_HISTORY_STEP_THREE_CREATE_SUCCESS',
    STEP_THREE_CREATE_FAILURE: 'MEDICAL_HISTORY_STEP_THREE_CREATE_FAILURE',

    MEDICAL_HISTORY_GET_QUESTIONS_REQUEST: 'MEDICAL_HISTORY_GET_QUESTIONS_REQUEST',
    MEDICAL_HISTORY_GET_QUESTIONS_SUCCESS: 'MEDICAL_HISTORY_GET_QUESTIONS_SUCCESS',
    MEDICAL_HISTORY_GET_QUESTIONS_FAILURE: 'MEDICAL_HISTORY_GET_QUESTIONS_FAILURE',

    GET_MEDICAL_HISTORY_REQUEST: 'GET_MEDICAL_HISTORY_REQUEST',
    GET_MEDICAL_HISTORY_SUCCESS: 'GET_MEDICAL_HISTORY_SUCCESS',
    GET_MEDICAL_HISTORY_FAILURE: 'GET_MEDICAL_HISTORY_FAILURE',

    UPDATE_MEDICAL_HISTORY_REQUEST: 'UPDATE_MEDICAL_HISTORY_REQUEST',
    UPDATE_MEDICAL_HISTORY_SUCCESS: 'UPDATE_MEDICAL_HISTORY_SUCCESS',
    UPDATE_MEDICAL_HISTORY_FAILURE: 'UPDATE_MEDICAL_HISTORY_FAILURE',

    CREATE_PATIENT_ALLERGIC_SUBSTANCES_REQUEST: 'CREATE_PATIENT_ALLERGIC_SUBSTANCES_REQUEST',
    CREATE_PATIENT_ALLERGIC_SUBSTANCES_SUCCESS: 'CREATE_PATIENT_ALLERGIC_SUBSTANCES_SUCCESS',
    CREATE_PATIENT_ALLERGIC_SUBSTANCES_FAILURE: 'CREATE_PATIENT_ALLERGIC_SUBSTANCES_FAILURE',

    DOWNLOAD_REQUEST: 'DEMOGRAPHICS_DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'DEMOGRAPHICS_DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE: 'DEMOGRAPHICS_DOWNLOAD_FAILURE',

    GET_MEDICAL_PROVIDER_REQUEST: 'GET_MEDICAL_PROVIDER_REQUEST',
    GET_MEDICAL_PROVIDER_SUCCESS: 'GET_MEDICAL_PROVIDER_SUCCESS',
    GET_MEDICAL_PROVIDER_FAILURE: 'GET_MEDICAL_PROVIDER_FAILURE',


    GET_PATIENT_SELECTED_MEDICAL_PROVIDER_REQUEST: 'GET_PATIENT_SELECTED_MEDICAL_PROVIDER_REQUEST',
    GET_PATIENT_SELECTED_MEDICAL_PROVIDER_SUCCESS: 'GET_PATIENT_SELECTED_MEDICAL_PROVIDER_SUCCESS',
    GET_PATIENT_SELECTED_MEDICAL_PROVIDER_FAILURE: 'GET_PATIENT_SELECTED_MEDICAL_PROVIDER_FAILURE',

    UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_REQUEST: 'UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_REQUEST',
    UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_SUCCESS: 'UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_SUCCESS',
    UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_FAILURE: 'UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_FAILURE',

    CREATE_PATIENT_MEDICAL_PROVIDER_REQUEST: 'CREATE_PATIENT_MEDICAL_PROVIDER_REQUEST',
    CREATE_PATIENT_MEDICAL_PROVIDER_SUCCESS: 'CREATE_PATIENT_MEDICAL_PROVIDER_SUCCESS',
    CREATE_PATIENT_MEDICAL_PROVIDER_FAILURE: 'CREATE_PATIENT_MEDICAL_PROVIDER_FAILURE',

    DELETE_PATIENT_MEDICAL_PROVIDER_REQUEST: 'DELETE_PATIENT_MEDICAL_PROVIDER_REQUEST',
    DELETE_PATIENT_MEDICAL_PROVIDER_SUCCESS: 'DELETE_PATIENT_MEDICAL_PROVIDER_SUCCESS',
    DELETE_PATIENT_MEDICAL_PROVIDER_FAILURE: 'DELETE_PATIENT_MEDICAL_PROVIDER_FAILURE',

    GET_ALL_ASSESSMENT_SUMMARY_REQUEST: 'GET_ALL_ASSESSMENT_SUMMARY_REQUEST',
    GET_ALL_ASSESSMENT_SUMMARY_SUCCESS: 'GET_ALL_ASSESSMENT_SUMMARY_SUCCESS',
    GET_ALL_ASSESSMENT_SUMMARY_FAILURE: 'GET_ALL_ASSESSMENT_SUMMARY_FAILURE',

    UPDATE_ASSESSMENT_SUMMARY_REQUEST: 'UPDATE_ASSESSMENT_SUMMARY_REQUEST',
    UPDATE_ASSESSMENT_SUMMARY_SUCCESS: 'UPDATE_ASSESSMENT_SUMMARY_SUCCESS',
    UPDATE_ASSESSMENT_SUMMARY_FAILURE: 'UPDATE_ASSESSMENT_SUMMARY_FAILURE',

    CREATE_ASSESSMENT_SUMMARY_REQUEST: 'CREATE_ASSESSMENT_SUMMARY_REQUEST',
    CREATE_ASSESSMENT_SUMMARY_SUCCESS: 'CREATE_ASSESSMENT_SUMMARY_SUCCESS',
    CREATE_ASSESSMENT_SUMMARY_FAILURE: 'CREATE_ASSESSMENT_SUMMARY_FAILURE',

    DELETE_MEMBER_REQUEST: 'DELETE_MEMBER_REQUEST',
    DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
    DELETE_MEMBER_FAILURE: 'DELETE_MEMBER_FAILURE',

    GET_ALL_MEDICINE_REASON_REQUEST: 'GET_ALL_MEDICINE_STOPPING_REASON_REQUEST',
    GET_ALL_MEDICINE_REASON_SUCCESS: 'GET_ALL_MEDICINE_STOPPING_REASON_SUCCESS',
    GET_ALL_MEDICINE_REASON_FAILURE: 'GET_ALL_MEDICINE_STOPPING_REASON_FAILURE',

    CREATE_MEDICINE_REASON_REQUEST: 'CREATE_MEDICINE_STOPPING_REASON_REQUEST',
    CREATE_MEDICINE_REASON_SUCCESS: 'CREATE_MEDICINE_STOPPING_REASON_SUCCESS',
    CREATE_MEDICINE_REASON_FAILURE: 'CREATE_MEDICINE_STOPPING_REASON_FAILURE',

    DELETE_MEDICINE_REASON_REQUEST: 'DELETE_MEDICINE_STOPPING_REASON_REQUEST',
    DELETE_MEDICINE_REASON_SUCCESS: 'DELETE_MEDICINE_STOPPING_REASON_SUCCESS',
    DELETE_MEDICINE_REASON_FAILURE: 'DELETE_MEDICINE_STOPPING_REASON_FAILURE',

    UPDATE_MEDICINE_REASON_REQUEST: 'UPDATE_MEDICINE_STOPPING_REASON_REQUEST',
    UPDATE_MEDICINE_REASON_SUCCESS: 'UPDATE_MEDICINE_STOPPING_REASON_SUCCESS',
    UPDATE_MEDICINE_REASON_FAILURE: 'UPDATE_MEDICINE_STOPPING_REASON_FAILURE',


    GET_MANAGE_DEPENDENT_REQUEST : 'GET_MANAGE_DEPENDENT_EMAIL_REQUEST',
    GET_MANAGE_DEPENDENT_SUCCESS : 'GET_MANAGE_DEPENDENT_EMAIL_SUCCESS',
    GET_MANAGE_DEPENDENT_FAILURE : 'GET_MANAGE_DEPENDENT_EMAIL_FAILURE',

    UPDATE_MANAGE_DEPENDENT_REQUEST : 'UPDATE_MANAGE_DEPENDENT_EMAIL_REQUEST',
    UPDATE_MANAGE_DEPENDENT_SUCCESS : 'UPDATE_MANAGE_DEPENDENT_EMAIL_SUCCESS',
    UPDATE_MANAGE_DEPENDENT_FAILURE : 'UPDATE_MANAGE_DEPENDENT_EMAIL_FAILURE',

    UPDATE_MANAGE_PARENT_REQUEST : 'UPDATE_MANAGE_PARENT_EMAIL_REQUEST',
    UPDATE_MANAGE_PARENT_SUCCESS : 'UPDATE_MANAGE_PARENT_EMAIL_SUCCESS',
    UPDATE_MANAGE_PARENT_FAILURE : 'UPDATE_MANAGE_PARENT_EMAIL_FAILURE',

    GET_ALL_MEDICATION_SETTINGS_REQUEST : 'GET_ALL_MEDICATION_SETTINGS_REQUEST',
    GET_ALL_MEDICATION_SETTINGS_SUCCESS : 'GET_ALL_MEDICATION_SETTINGS_SUCCESS',
    GET_ALL_MEDICATION_SETTINGS_FAILURE : 'GET_ALL_MEDICATION_SETTINGS_FAILURE',


    UPDATE_MEDICATION_SETTINGS_REQUEST : 'UPDATE_MEDICATION_SETTINGS_REQUEST',
    UPDATE_MEDICATION_SETTINGS_SUCCESS : 'UPDATE_MEDICATION_SETTINGS_SUCCESS',
    UPDATE_MEDICATION_SETTINGS_FAILURE : 'UPDATE_MEDICATION_SETTINGS_FAILURE',

    DELETE_MEDICATION_SETTINGS_REQUEST : 'DELETE_MEDICATION_SETTINGS_REQUEST',
    DELETE_MEDICATION_SETTINGS_SUCCESS : 'DELETE_MEDICATION_SETTINGS_SUCCESS',
    DELETE_MEDICATION_SETTINGS_FAILURE : 'DELETE_MEDICATION_SETTINGS_FAILURE',

    CLEAR_MEMBERS: 'CLEAR_MEMBERS_PROPS',

};
