import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';

import { IndexRoutes } from "js/routes/index";

import { configureStore } from "js/helpers";

import { history } from "js/helpers";

const store = configureStore();


class App extends React.Component {
	render() {
		return (
			<Provider store={store} >
				<ConnectedRouter history={history} >
					<Suspense fallback={
                        <div className="initial-loader">
                          <img src="/images/loading.svg" alt="" />
                        </div>
                    } >
						<Switch>
							{IndexRoutes.map((prop, key) => {
								return <Route path={prop.path} component={prop.component} key={key} />;
							})}
						</Switch>
					</Suspense>
				</ConnectedRouter>
			</Provider>
		);
	}
}

export default App;
