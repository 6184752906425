export const progressNoteConstants = {

     GET_ALL_PROGRESS_NOTES_REQUEST: 'GET_ALL_PROGRESS_NOTES_REQUEST',
     GET_ALL_PROGRESS_NOTES_SUCCESS: 'GET_ALL_PROGRESS_NOTES_SUCCESS',
     GET_ALL_PROGRESS_NOTES_FAILURE: 'GET_ALL_PROGRESS_NOTES_FAILURE',

     ADD_PROGRESS_NOTES_REQUEST: 'ADD_PROGRESS_NOTES_REQUEST',
     ADD_PROGRESS_NOTES_SUCCESS: 'ADD_PROGRESS_NOTES_SUCCESS',
     ADD_PROGRESS_NOTES_FAILURE: 'ADD_PROGRESS_NOTES_FAILURE',

     UPDATE_PROGRESS_NOTES_REQUEST: 'UPDATE_PROGRESS_NOTES_REQUEST',
     UPDATE_PROGRESS_NOTES_SUCCESS: 'UPDATE_PROGRESS_NOTES_SUCCESS',
     UPDATE_PROGRESS_NOTES_FAILURE: 'UPDATE_PROGRESS_NOTES_FAILURE',

     DELETE_PROGRESS_NOTES_REQUEST: 'DELETE_PROGRESS_NOTES_REQUEST',
     DELETE_PROGRESS_NOTES_SUCCESS: 'DELETE_PROGRESS_NOTES_SUCCESS',
     DELETE_PROGRESS_NOTES_FAILURE: 'DELETE_PROGRESS_NOTES_FAILURE',

     CLEAN_UP_PROGRESS_NOTE_REDUCER: 'CLEAN_UP_PROGRESS_NOTE_REDUCER'
};