export const callCenterConstants = {

    GET_ALL_REQUEST: 'CALL_CENTER_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CALL_CENTER_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CALL_CENTER_GET_ALL_FAILURE',

    GET_REQUEST: 'CALL_CENTER_GET_REQUEST',
    GET_SUCCESS: 'CALL_CENTER_GET_SUCCESS',
    GET_FAILURE: 'CALL_CENTER_GET_FAILURE',

    CREATE_PROXY_REQUEST: 'CALL_CENTER_CREATE_PROXY_REQUEST',
    CREATE_PROXY_SUCCESS: 'CALL_CENTER_CREATE_PROXY_SUCCESS',
    CREATE_PROXY_FAILURE: 'CALL_CENTER_CREATE_PROXY_FAILURE',

    CREATE_CARE_TEAM_PROXY_REQUEST: 'CALL_CENTER_CREATE_CARE_TEAM_PROXY_REQUEST',
    CREATE_CARE_TEAM_PROXY_SUCCESS: 'CALL_CENTER_CREATE_CARE_TEAM_PROXY_SUCCESS',
    CREATE_CARE_TEAM_PROXY_FAILURE: 'CALL_CENTER_CREATE_CARE_TEAM_PROXY_FAILURE',

    GET_ALL_NOTE_REQUEST: 'CALL_CENTER_GET_ALL_NOTE_REQUEST',
    GET_ALL_NOTE_SUCCESS: 'CALL_CENTER_GET_ALL_NOTE_SUCCESS',
    GET_ALL_NOTE_FAILURE: 'CALL_CENTER_GET_ALL_NOTE_FAILURE',

    CREATE_NOTE_REQUEST: 'CALL_CENTER_CREATE_NOTE_REQUEST',
    CREATE_NOTE_SUCCESS: 'CALL_CENTER_CREATE_NOTE_SUCCESS',
    CREATE_NOTE_FAILURE: 'CALL_CENTER_CREATE_NOTE_FAILURE',

    GET_ALL_REQUEST_REQUEST: 'GET_ALL_CALL_REQUEST_REQUEST',
    GET_ALL_REQUEST_SUCCESS: 'GET_ALL_CALL_REQUEST_SUCCESS',
    GET_ALL_REQUEST_FAILURE: 'GET_ALL_CALL_REQUEST_FAILURE',

    GET_CALL_HISTORY_REQUEST: 'GET_CALL_REQUEST_HISTORY_REQUEST',
    GET_CALL_HISTORY_SUCCESS: 'GET_CALL_REQUEST_HISTORY_SUCCESS',
    GET_CALL_HISTORY_FAILURE: 'GET_CALL_REQUEST_HISTORY_FAILURE',

    CANCEL_CALL_REQUEST: 'CANCEL_CALL_REQUEST',
    CANCEL_CALL_SUCCESS: 'CANCEL_CALL_REQUEST_SUCCESS',
    CANCEL_CALL_FAILURE: 'CANCEL_CALL_REQUEST_FAILURE',

    MARK_CALL_AS_DONE_REQUEST: 'MARK_CALL_AS_DONE_REQUEST',
    MARK_CALL_AS_DONE_SUCCESS: 'MARK_CALL_REQUEST_AS_DONE_SUCCESS',
    MARK_CALL_AS_DONE_FAILURE: 'MARK_CALL_REQUEST_AS_DONE_FAILURE',

    GET_CALL_REQUESTS_REQUEST: 'CALL_CENTER_GET_CALL_REQUESTS_REQUEST',
    GET_CALL_REQUESTS_SUCCESS: 'CALL_CENTER_GET_CALL_REQUESTS_SUCCESS',
    GET_CALL_REQUESTS_FAILURE: 'CALL_CENTER_GET_CALL_REQUESTS_FAILURE',

    CLEAR  : 'CALL_CENTER_CLEAR',

};
