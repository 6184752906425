import { eventConstants } from 'js/constants';
import { omit } from 'lodash';

const initialState = {};

export function event(state = initialState, action) {
    switch (action.type) {
        case eventConstants.CLEAR_PROPS:
            return {
                ...omit(state, [action.payload])
            }

        case eventConstants.GET_ALL_REQUEST:
            return {
                ...state,
                get_all_events_request: true
            };
        case eventConstants.GET_ALL_SUCCESS:
            return {
                ...omit(state, 'get_all_events_request'),
                events: action.response
            }
        case eventConstants.GET_ALL_FAILURE:
            return {
                ...omit(state, 'get_all_events_request'),
            }

        case eventConstants.UPDATE_REQUEST:
            return {
                ...state,
                event_update_request: true,
                loading: true,
            };
        case eventConstants.UPDATE_SUCCESS:
            return {
                ...omit(state, 'event_update_request', 'loading'),
                updated_event: action.response,
            }
        case eventConstants.UPDATE_FAILURE:
            return {
                ...omit(state, 'event_update_request', 'loading'),
                create_error: action.response
            }

        case eventConstants.ADD_REQUEST:
            return {
                ...state,
                event_create_request: true,
                loading: true
            };
        case eventConstants.ADD_SUCCESS:
            return {
                ...omit(state, 'event_create_request', 'loading'),
                created_event: action.response
            }
        case eventConstants.ADD_FAILURE:
            return {
                ...omit(state, 'event_create_request', 'loading'),
                create_error: action.response
            }

        case eventConstants.CANCEL_REQUEST:
            return {
                ...state,
                event_cancel_request: true
            }
        case eventConstants.CANCEL_SUCCESS:
            return {
                ...omit(state, 'event_cancel_request'),
                cancelled_event: action.response
            }
        case eventConstants.CANCEL_FAILURE:
            return {
                ...omit(state, 'event_cancel_request'),
                create_error: action.response
            }


        case eventConstants.GET_REQUEST:
            return {
                ...state,
                get_event_request: true
            }
        case eventConstants.GET_SUCCESS:
            return {
                ...omit(state, 'get_event_request'),
                selected_event: action.response
            }
        case eventConstants.GET_FAILURE:
            return {
                ...omit(state, 'get_event_request'),
                create_error: action.response
            }

        case eventConstants.REMOVE_REQUEST:
            return {
                ...state,
                remove_event_request: true
            }
        case eventConstants.REMOVE_SUCCESS:
            return {
                ...omit(state, 'remove_event_request'),
                removed_event: true
            }
        case eventConstants.REMOVE_FAILURE:
            return {
                ...omit(state, 'remove_event_request'),
                create_error: action.response
            }

        default:
            return state;
    }
}
