export const dashboardConstants = {
    GET_ALL_DASHBOARD_DATA_REQUEST: 'GET_ALL_DASHBOARD_DATA_REQUEST',
    GET_ALL_DASHBOARD_DATA_SUCCESS: 'GET_ALL_DASHBOARD_DATA_SUCCESS',
    GET_ALL_DASHBOARD_DATA_FAILURE: 'GET_ALL_DASHBOARD_DATA_FAILURE',

    CLEAR_DASHBOARD_PROPS: 'CLEAR_DASHBOARD_PROPS',

    GET_ALL_CARE_ASSISTANTS_REQUEST: 'GET_ALL_CARE_ASSISTANTS_REQUEST',
    GET_ALL_CARE_ASSISTANTS_SUCCESS: 'GET_ALL_CARE_ASSISTANTS_SUCCESS',
    GET_ALL_CARE_ASSISTANTS_FAILURE: 'GET_ALL_CARE_ASSISTANTS_FAILURE',

    GET_CARE_PERSON_TASK_REQUEST: 'GET_CARE_PERSON_TASK_REQUEST',
    GET_CARE_PERSON_TASK_SUCCESS: 'GET_CARE_PERSON_TASK_SUCCESS',
    GET_CARE_PERSON_TASK_FAILURE: 'GET_CARE_PERSON_TASK_FAILURE',

    GET_RECENT_CARE_PERSON_CALL_REQUEST: 'GET_RECENT_CARE_PERSON_CALL_REQUEST',
    GET_RECENT_CARE_PERSON_CALL_SUCCESS: 'GET_RECENT_CARE_PERSON_CALL_SUCCESS',
    GET_RECENT_CARE_PERSON_CALL_FAILURE: 'GET_RECENT_CARE_PERSON_CALL_FAILURE',

    GET_RECENT_CARE_PERSON_ACTIVITIES_REQUEST: 'GET_RECENT_CARE_PERSON_ACTIVITIES_REQUEST',
    GET_RECENT_CARE_PERSON_ACTIVITIES_SUCCESS: 'GET_RECENT_CARE_PERSON_ACTIVITIES_SUCCESS',
    GET_RECENT_CARE_PERSON_ACTIVITIES_FAILURE: 'GET_RECENT_CARE_PERSON_ACTIVITIES_FAILURE',

    GET_RECENT_CARE_PERSON_MESSAGES_REQUEST: 'GET_RECENT_CARE_PERSON_MESSAGES_REQUEST',
    GET_RECENT_CARE_PERSON_MESSAGES_SUCCESS: 'GET_RECENT_CARE_PERSON_MESSAGES_SUCCESS',
    GET_RECENT_CARE_PERSON_MESSAGES_FAILURE: 'GET_RECENT_CARE_PERSON_MESSAGES_FAILURE',


    GET_ALL_RECENT_REQUEST: 'DASHBOARD_GET_ALL_RECENT_REQUEST',
    GET_ALL_RECENT_SUCCESS: 'DASHBOARD_GET_ALL_RECENT_SUCCESS',
    GET_ALL_RECENT_FAILURE: 'DASHBOARD_GET_ALL_RECENT_FAILURE',
};
