export const configConstants = {
    ADMIN_ROUTE_NAME: 'admin',
    ADMIN_TOKEN_NAME: 'modernadmin_token',

    NURSE_ROUTE_NAME: 'nurse',
    NURSE_TOKEN_NAME: 'nurse_token',

    PATIENT_ROUTE_NAME: 'patient',
    PATIENT_TOKEN_NAME: 'patient_token',

    ACCOUNT_SWITCHED: 'account_switched'
};
