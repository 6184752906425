import { progressNoteConstants, authConstants } from 'js/constants'
import { omit } from 'lodash';

export function progressNotes(state = {}, action) {
     switch (action.type) {

          case authConstants.LOGOUT_SUCCESS:
            return {};

          case progressNoteConstants.GET_ALL_PROGRESS_NOTES_REQUEST:
               return {
                    ...state,
                    [action.payload]: true,
               };
          case progressNoteConstants.GET_ALL_PROGRESS_NOTES_SUCCESS:
               return {
                    ...omit(state, [action.payload.active]),
                    [action.payload.success]: action.response
               };
          case progressNoteConstants.GET_ALL_PROGRESS_NOTES_FAILURE:
               return {
                    ...omit(state, [action.payload.active]),
                    [action.payload.error]: action.response
               };
          case progressNoteConstants.ADD_PROGRESS_NOTES_REQUEST:
               return {
                    ...state,
                    add_notes_request: true,
                    progress_note_add_success: false
               };
          case progressNoteConstants.ADD_PROGRESS_NOTES_SUCCESS:
               return {
                    ...omit(state, 'add_notes_request'),
                    progress_note_add_success: true,
                    progress_notes_added: action.response
               };
          case progressNoteConstants.ADD_PROGRESS_NOTES_FAILURE:
               return {
                    ...omit(state, 'add_notes_request',
                         'progress_note_add_success',
                         'progress_note_add_success'),
                    created_error: action.response
               };
          case progressNoteConstants.UPDATE_PROGRESS_NOTES_REQUEST:
               return {
                    ...state,
                    update_notes_request: true
               }
          case progressNoteConstants.UPDATE_PROGRESS_NOTES_SUCCESS:
               return {
                    ...omit(state, 'update_notes_request'),
                    updated_notes: action.response
               }
          case progressNoteConstants.UPDATE_PROGRESS_NOTES_FAILURE:
               return {
                    ...omit(state, 'update_notes_request'),
                    created_error: action.response
               }
          case progressNoteConstants.DELETE_PROGRESS_NOTES_REQUEST:
               return {
                    ...state,
                    request_delete: true
               }
          case progressNoteConstants.DELETE_PROGRESS_NOTES_SUCCESS:
               return {
                    ...omit(state, 'request_delete'),
                    delete_success: true,
               }
          case progressNoteConstants.DELETE_PROGRESS_NOTES_FAILURE:
               return {
                    ...omit(state, 'request_delete', 'delete_success'),
                    created_error: action.response
               }

          case progressNoteConstants.CLEAN_UP_PROGRESS_NOTE_REDUCER:
               return {
                    ...omit(state, [action.payload])
               };

          default:
               return state;
     }
}
