export const medicalRecordsConstants = {
    GET_MEDICAL_RECORDS_REQUEST: 'GET_MEDICAL_RECORDS_REQUEST',
    GET_MEDICAL_RECORDS_SUCCESS: 'GET_MEDICAL_RECORDS_SUCCESS',
    GET_MEDICAL_RECORDS_FAILURE: 'GET_MEDICAL_RECORDSFAILURE',

    CREATE_MEDICAL_RECORDS_REQUEST: 'CREATE_MEDICAL_RECORDS_REQUEST',
    CREATE_MEDICAL_RECORDS_SUCCESS: 'CREATE_MEDICAL_RECORDS_SUCCESS',
    CREATE_MEDICAL_RECORDS_FAILURE: 'CREATE_MEDICAL_RECORDS_FAILURE',

    UPDATE_REQUEST: 'MEDICAL_RECORDS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'MEDICAL_RECORDS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MEDICAL_RECORDS_UPDATE_FAILURE',

    CLEAR_MEDICAL_RECORDS_PROPS: 'CLEAR_MEDICAL_RECORDS_PROPS',

    CREATE_FILE_ATTACHEMENT_REQUEST: 'MEDICAL_RECORD_CREATE_FILE_ATTACHEMENT_REQUEST',
    CREATE_FILE_ATTACHEMENT_SUCCESS: 'MEDICAL_RECORD_CREATE_FILE_ATTACHEMENT_SUCCESS',
    CREATE_FILE_ATTACHEMENT_FAILURE: 'MEDICAL_RECORD_CREATE_FILE_ATTACHEMENT_FAILURE',

    // GET_ALL_PROCEDURES_REQUEST: 'GET_ALL_PROCEDURESS_REQUEST',
    // GET_ALL_PROCEDURES_SUCCESS: 'GET_ALL_PROCEDURES_SUCCESS',
    // GET_ALL_PROCEDURES_FAILURE: 'GET_ALL_PROCEDURES_FAILURE',

    // GET_ALL_HOSPITAL_VISIT_REQUEST: 'GET_ALL_SHARED_FILES_REQUEST',
    // GET_ALL_SHARED_FILES_SUCCESS: 'GET_ALL_SHARED_FILES_SUCCESS',
    // GET_ALL_SHARED_FILES_FAILURE: 'GET_ALL_SHARED_FILES_FAILURE',

    // GET_ALL_SHARED_FILES_REQUEST: 'GET_ALL_SHARED_FILES_REQUEST',
    // GET_ALL_SHARED_FILES_SUCCESS: 'GET_ALL_SHARED_FILES_SUCCESS',
    // GET_ALL_SHARED_FILES_FAILURE: 'GET_ALL_SHARED_FILES_FAILURE',

    // GET_ALL_SHARED_FILES_REQUEST: 'GET_ALL_SHARED_FILES_REQUEST',
    // GET_ALL_SHARED_FILES_SUCCESS: 'GET_ALL_SHARED_FILES_SUCCESS',
    // GET_ALL_SHARED_FILES_FAILURE: 'GET_ALL_SHARED_FILES_FAILURE',
};
