import { companyConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';

export function company(state = {}, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return {};

        case companyConstants.GET_ALL_COMPANIES_REQUEST:
            return {
                ...state,
                get_all_request: true
            };
        case companyConstants.GET_ALL_COMPANIES_SUCCESS:
            return {
                ...omit(state, 'get_all_request'),
                companies: action.response
            }
        case companyConstants.GET_ALL_COMPANIES_FAILURE:
            return {
                ...omit(state, 'get_all_request'),
                create_error: action.response
            }

        case companyConstants.GET_COMPANY_REQUEST:
            return {
                ...state,
                get_request: true
            };
        case companyConstants.GET_COMPANY_SUCCESS:
            return {
                ...omit(state, 'get_all_request'),
                selected_company: action.response
            }
        case companyConstants.GET_COMPANY_FAILURE:
            return {
                ...omit(state, 'get_all_request'),
                create_error: action.response
            }

        case companyConstants.UPDATE_COMPANY_REQUEST:
            return {
                ...state,
                update_request: true
            };
        case companyConstants.UPDATE_COMPANY_SUCCESS:
            return {
                ...omit(state, 'update_request'),
                updated_company: action.response
            }
        case companyConstants.UPDATE_COMPANY_FAILURE:
            return {
                ...omit(state, 'update_request'),
                create_error: action.response
            }

        case companyConstants.CREATE_COMPANY_REQUEST:
            return {
                ...state,
                create_request: true
            };
        case companyConstants.CREATE_COMPANY_SUCCESS:
            return {
                ...omit(state, 'create_request'),
                created_company: action.response
            }
        case companyConstants.CREATE_COMPANY_FAILURE:
            return {
                ...omit(state, 'create_request'),
                create_error: action.response
            }

        case companyConstants.REMOVE_COMPANY_REQUEST:
            return {
                ...state,
                remove_request: true
            };
        case companyConstants.REMOVE_COMPANY_SUCCESS:
            return {
                ...omit(state, 'remove_request'),
                removed_comopany: action.response
            }
        case companyConstants.REMOVE_COMPANY_FAILURE:
            return {
                ...omit(state, 'remove_request'),
                create_error: action.response
            }

        case companyConstants.CLEAR_COMPANY_ERRORS:
            return {
                ...omit(state, [action.payload])
            }

        case companyConstants.SET_CURRENT_PAGE:
            return {
                ...state,
                current_page: action.payload
            }
        case companyConstants.GET_ALL_COMPANIES_FILTER_REQUEST:
            return {
                ...state,
                request_company_filter: action.payload
            }

        case companyConstants.GET_ALL_COMPANIES_FILTER_SUCCESS:
            return {
                ...omit(state, 'request_company_filter'),
                company_filter: action.response
            }
        case companyConstants.GET_ALL_COMPANIES_FILTER_FAILURE:
            return {
                ...state,
                company_filter: action.response
            }
        case companyConstants.GET_ALL_IMPORT_EMPLOYEE_REQUEST:
            return {
                ...state,
                import_req: true,
                import_request: action.payload
            }

        case companyConstants.GET_ALL_IMPORT_EMPLOYEE_SUCCESS:
            return {
                ...omit(state, 'import_request', 'import_req', 'import_failure'),
                import_success: true,
                company_import_status: action.response
            }
        case companyConstants.GET_ALL_IMPORT_EMPLOYEE_FAILURE:
            return {
                ...omit(state, 'import_request', 'import_req', 'import_success'),
                import_failure: false,
                company_import_failure: action.response
            }
        case companyConstants.CLEAR_IMPORT_EMPLOYEE_ERRORS:
            return {
                ...omit(state, ['company_import_failure', 'company_import_status']),
            }

        default:
            return state;
    }
}






