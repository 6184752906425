import { membersConstants, authConstants } from 'js/constants';
import { omit } from "lodash";

const initialState = {};

export function members(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case membersConstants.GET_PATIENT_LIST_REQUEST:
            return {
                ...omit(state, 'patients'),
                patient_list_request: true,
            };
        case membersConstants.GET_PATIENT_LIST_SUCCESS:
            return {
                ...omit(state, 'patient_list_request'),
                patients: action.response
            };
        case membersConstants.GET_PATIENT_LIST_FAILURE:
            return {
                ...omit(state, 'patient_list_request'),
                created_errors: action.response
            };

        case membersConstants.PATIENT_GET_REQUEST:
            return {
                ...state,
                patient_get_request: true,
            };
        case membersConstants.PATIENT_GET_SUCCESS:
            return {
                ...omit(state, 'patient_get_request'),
                selected_patient: action.response
            };
        case membersConstants.PATIENT_GET_FAILURE:
            return {
                ...omit(state, 'patient_get_request')
            };

        case membersConstants.PATIENT_UPDATE_REQUEST:
            return {
                ...omit(state, 'update_errors'),
                patient_update_request: true,
            };
        case membersConstants.PATIENT_UPDATE_SUCCESS:
            return {
                ...omit(state, 'patient_update_request'),
                updated_patient: action.response
            };
        case membersConstants.PATIENT_UPDATE_FAILURE:
            return {
                ...omit(state, 'patient_update_request'),
                update_errors: action.response
            };

        case membersConstants.GET_MEDICAL_CONDITIONS_REQUEST:
            return {
                ...state,
                medical_condition_request: true,
            };
        case membersConstants.GET_MEDICAL_CONDITIONS_SUCCESS:
            return {
                ...omit(state, 'medical_condition_request'),
                medical_conditions: action.response
            };
        case membersConstants.GET_MEDICAL_CONDITIONS_FAILURE:
            return {
                ...omit(state, 'patient_update_request'),
                created_errors: action.response
            };

        case membersConstants.GET_SELECTED_MEDICAL_CONDITIONS_REQUEST:
            return {
                ...state,
                selected_medical_condition_request: true,
            };
        case membersConstants.GET_SELECTED_MEDICAL_CONDITIONS_SUCCESS:
            return {
                ...omit(state, 'selected_medical_condition_request'),
                selected_medical_condition: action.response
            };
        case membersConstants.GET_SELECTED_MEDICAL_CONDITIONS_FAILURE:
            return {
                ...omit(state, 'selected_medical_condition_request'),
                created_errors: action.response
            };

        case membersConstants.CREATE_MEDICAL_CONDITIONS_REQUEST:
            return {
                ...state,
                create_medical_condition_request: true,
            };
        case membersConstants.CREATE_MEDICAL_CONDITIONS_SUCCESS:
            return {
                ...omit(state, 'create_medical_condition_request'),
                medical_condition_created: action.response
            };
        case membersConstants.CREATE_MEDICAL_CONDITIONS_FAILURE:
            return {
                ...omit(state, 'create_medical_condition_request'),
                created_errors: action.response
            };

        case membersConstants.UPDATE_MEDICAL_CONDITIONS_REQUEST:
            return {
                ...state,
                update_medical_condition_request: true,
            };
        case membersConstants.UPDATE_MEDICAL_CONDITIONS_SUCCESS:
            return {
                ...omit(state, 'update_medical_condition_request'),
                medical_condition_updated: action.response
            };
        case membersConstants.UPDATE_MEDICAL_CONDITIONS_FAILURE:
            return {
                ...omit(state, 'update_medical_condition_request'),
                created_errors: action.response
            };

        case membersConstants.DELETE_MEDICAL_CONDITIONS_REQUEST:
            return {
                ...state,
                delete_medical_condition_request: true,
            };
        case membersConstants.DELETE_MEDICAL_CONDITIONS_SUCCESS:
            return {
                ...omit(state, 'delete_medical_condition_request'),
                medical_condition_deleted: action.response
            };
        case membersConstants.DELETE_MEDICAL_CONDITIONS_FAILURE:
            return {
                ...omit(state, 'delete_medical_condition_request'),
                created_errors: action.response
            };


        case membersConstants.GET_PATIENTS_CARE_TEAM_REQUEST:
            return {
                ...omit(state, 'update_errors'),
                patientS_care_team_request: true,
            };
        case membersConstants.GET_PATIENTS_CARE_TEAM_SUCCESS:
            return {
                ...omit(state, 'patientS_care_team_request'),
                care_team: action.response
            };
        case membersConstants.GET_PATIENTS_CARE_TEAM_FAILURE:
            return {
                ...omit(state, 'patientS_care_team_request'),
                created_error: action.response
            };

        case membersConstants.GET_SURGICAL_OPERATIONS_REQUEST:
            return {
                ...state,
                surgical_operations_request: true,
            };
        case membersConstants.GET_SURGICAL_OPERATIONS_SUCCESS:
            return {
                ...omit(state, 'surgical_operations_request'),
                surgical_operations: action.response
            };
        case membersConstants.GET_SURGICAL_OPERATIONS_FAILURE:
            return {
                ...omit(state, 'surgical_operations_request'),
                created_errors: action.response
            };

        case membersConstants.GET_SELECTED_SURGICAL_OPERATION_REQUEST:
            return {
                ...state,
                selected_surgical_operation_request: true,
            };
        case membersConstants.GET_SELECTED_SURGICAL_OPERATION_SUCCESS:
            return {
                ...omit(state, 'selected_surgical_operation_request'),
                selected_surgical_operation: action.response
            };
        case membersConstants.GET_SELECTED_SURGICAL_OPERATION_FAILURE:
            return {
                ...omit(state, 'selected_surgical_operation_request'),
                created_errors: action.response
            };

        case membersConstants.CREATE_SURGICAL_OPERATIONS_REQUEST:
            return {
                ...state,
                surgical_operation_request: true,
            };
        case membersConstants.CREATE_SURGICAL_OPERATIONS_SUCCESS:
            return {
                ...omit(state, 'surgical_operation_request'),
                created_surgical_operation: action.response
            };
        case membersConstants.CREATE_SURGICAL_OPERATIONS_FAILURE:
            return {
                ...omit(state, 'surgical_operation_request'),
                created_errors: action.response
            };


        case membersConstants.UPDATE_SURGICAL_OPERATIONS_REQUEST:
            return {
                ...state,
                surgical_operation_request: true,
            };
        case membersConstants.UPDATE_SURGICAL_OPERATIONS_SUCCESS:
            return {
                ...omit(state, 'surgical_operation_request'),
                updated_surgical_operation: action.response
            };
        case membersConstants.UPDATE_SURGICAL_OPERATIONS_FAILURE:
            return {
                ...omit(state, 'surgical_operation_request'),
                created_errors: action.response
            };


        case membersConstants.GET_PATIENT_ALLERGIC_SUBSTANCES_REQUEST:
            return {
                ...state,
                get_patient_allergic_substance_request: true,
            };
        case membersConstants.GET_PATIENT_ALLERGIC_SUBSTANCES_SUCCESS:
            return {
                ...omit(state, 'get_patient_allergic_substance_request'),
                patient_allergies: action.response
            };
        case membersConstants.GET_PATIENT_ALLERGIC_SUBSTANCES_FAILURE:
            return {
                ...omit(state, 'get_patient_allergic_substance_request'),
                created_errors: action.response
            };

        case membersConstants.CREATE_PATIENT_ALLERGIC_SUBSTANCES_REQUEST:
            return {
                ...state,
                create_patient_allergic_substance_request: true,
            };
        case membersConstants.CREATE_PATIENT_ALLERGIC_SUBSTANCES_SUCCESS:
            return {
                ...omit(state, 'create_patient_allergic_substance_request'),
                created_patient_allergies: action.response
            };
        case membersConstants.CREATE_PATIENT_ALLERGIC_SUBSTANCES_FAILURE:
            return {
                ...omit(state, 'create_patient_allergic_substance_request'),
                created_errors: action.response
            };

        case membersConstants.CLEAR_MEMBERS:
            return {
                ...omit(state, [action.payload])
            };

        case membersConstants.ADD_NEW_MEDICATION_REQUEST:
            return {
                ...state,
                medicine_create_request: true
            };
        case membersConstants.ADD_NEW_MEDICATION_SUCCESS:
            return {
                ...omit(state, 'medicine_create_request'),
                created_medicine: action.response,
                medicine_create_success: true
            };
        case membersConstants.ADD_NEW_MEDICATION_FAILURE:
            return {
                ...omit(state, 'medicine_create_request'),
                created_errors: action.response
            };

        case membersConstants.GET_MEDICATIONS_REQUEST:
            return {
                ...state,
                [action.payload]: true,
            };
        case membersConstants.GET_MEDICATIONS_SUCCESS:
            return {
                ...omit(state, [action.payload.active]),
                [action.payload.success]: action.response
            };
        case membersConstants.GET_MEDICATIONS_FAILURE:
            return {
                ...omit(state, [action.payload.active]),
                [action.payload.error]: action.response
            };



        case membersConstants.REMOVE_MEDICATION_REQUEST:
            return {
                ...state,
                delete_medicine_request: true,
            };
        case membersConstants.REMOVE_MEDICATION_SUCCESS:
            return {
                ...omit(state, 'delete_medicine_request'),
                delete_medicine: action.response
            };
        case membersConstants.REMOVE_MEDICATION_FAILURE:
            return {
                ...omit(state, 'delete_medicine_request'),
                created_errors: action.response
            }


        case membersConstants.UPDATE_MEDICINE_REQUEST:
            return {
                ...state,
                medicine_update_request: true
            };
        case membersConstants.UPDATE_MEDICINE_SUCCESS:
            return {
                ...omit(state, 'medicine_update_request'),
                updated_medicine: action.response
            };
        case membersConstants.UPDATE_MEDICINE_FAILURE:
            return {
                ...omit(state, 'medicine_update_request'),
                created_errors: action.response
            };

        case membersConstants.GET_SELECTED_MEDICINE_REQUEST:
            return {
                ...state,
                get_selected_medicine_request: true
            };
        case membersConstants.GET_SELECTED_MEDICINE_SUCCESS:
            return {
                ...omit(state, 'get_selected_medicine_request'),
                selected_medicine: action.response
            }
        case membersConstants.GET_SELECTED_MEDICINE_FAILURE:
            return {
                ...omit(state, 'get_selected_medicine_request'),
                created_errors: action.response
            };

        case membersConstants.GET_MEDICINES_REQUEST:
            return {
                ...state,
                medication_request: true,
            }
        case membersConstants.GET_MEDICINES_SUCCESS:
            return {
                ...omit(state, 'medication_request'),
                medication: action.response
            };
        case membersConstants.GET_MEDICINES_FAILURE:
            return {
                ...omit(state, 'medication_request'),
                created_errors: action.response
            };

        case membersConstants.MEDICAL_HISTORY_STEP_ONE_CREATE_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                medical_history_step_one_create_request: true
            };
        case membersConstants.MEDICAL_HISTORY_STEP_ONE_CREATE_SUCCESS:
            return {
                ...omit(state, 'medical_history_step_one_create_request', 'created_errors'),
                created_step_one_medical_history: action.response
            }
        case membersConstants.MEDICAL_HISTORY_STEP_ONE_CREATE_FAILURE:
            return {
                ...omit(state, 'medical_history_step_one_create_request'),
                created_errors: action.response
            };

        case membersConstants.MEDICAL_HISTORY_STEP_TWO_CREATE_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                medical_history_step_two_create_request: true
            };
        case membersConstants.MEDICAL_HISTORY_STEP_TWO_CREATE_SUCCESS:
            return {
                ...omit(state, 'medical_history_step_two_create_request', 'created_errors'),
                created_step_two_medical_history: action.response
            }
        case membersConstants.MEDICAL_HISTORY_STEP_TWO_CREATE_FAILURE:
            return {
                ...omit(state, 'medical_history_step_two_create_request'),
                created_errors: action.response
            };

        case membersConstants.STEP_THREE_CREATE_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                medical_history_step_three_create_request: true
            };
        case membersConstants.STEP_THREE_CREATE_SUCCESS:
            return {
                ...omit(state, 'medical_history_step_three_create_request', 'created_errors'),
                created_step_three_medical_history: action.response
            }
        case membersConstants.STEP_THREE_CREATE_FAILURE:
            return {
                ...omit(state, 'medical_history_step_three_create_request'),
                created_errors: action.response
            };

        case membersConstants.MEDICAL_HISTORY_GET_QUESTIONS_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                medical_history_get_questions: true
            };
        case membersConstants.MEDICAL_HISTORY_GET_QUESTIONS_SUCCESS:
            return {
                ...omit(state, 'medical_history_get_questions'),
                medical_history_questions: action.response
            }
        case membersConstants.MEDICAL_HISTORY_GET_QUESTIONS_FAILURE:
            return {
                ...omit(state, 'medical_history_get_questions'),
                created_errors: action.response
            };

        case membersConstants.GET_MEDICAL_HISTORY_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                get_medical_history_request: true
            };
        case membersConstants.GET_MEDICAL_HISTORY_SUCCESS:
            return {
                ...omit(state, 'get_medical_history_request', 'created_errors'),
                medical_history: action.response
            }
        case membersConstants.GET_MEDICAL_HISTORY_FAILURE:
            return {
                ...omit(state, 'get_medical_history_request'),
                created_errors: action.response
            };

        case membersConstants.UPDATE_MEDICAL_HISTORY_REQUEST:
            return {
                ...omit(state, 'updated_errors', 'update_medical_history'),
                update_medical_history_request: true
            };
        case membersConstants.UPDATE_MEDICAL_HISTORY_SUCCESS:
            return {
                ...omit(state, 'update_medical_history_request', 'updated_errors'),
                updated_medical_history: action.response
            }
        case membersConstants.UPDATE_MEDICAL_HISTORY_FAILURE:
            return {
                ...omit(state, 'update_medical_history_request', 'updated_medical_history'),
                updated_errors: action.response
            };

        case membersConstants.DOWNLOAD_REQUEST:
            return {
                ...omit(state, ['downlod_success', 'downlod_failure']),
                downlod_request: true,
            };
        case membersConstants.DOWNLOAD_SUCCESS:
            return {
                ...omit(state, ['downlod_request', 'downlod_failure']),
                downlod_success: true,
            };
        case membersConstants.DOWNLOAD_FAILURE:
            return {
                ...omit(state, ['downlod_request', 'downlod_success']),
                downlod_failure: true,
            };

        case membersConstants.GET_MEDICAL_PROVIDER_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                get_medical_provider_request: true
            };
        case membersConstants.GET_MEDICAL_PROVIDER_SUCCESS:
            return {
                ...omit(state, 'get_medical_provider_request', 'created_errors'),
                medical_providers: action.response
            }
        case membersConstants.GET_MEDICAL_PROVIDER_FAILURE:
            return {
                ...omit(state, 'get_medical_provider_request'),
                created_errors: action.response
            };

        case membersConstants.GET_PATIENT_SELECTED_MEDICAL_PROVIDER_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                get_patient_selected_medical_provider_request: true
            };
        case membersConstants.GET_PATIENT_SELECTED_MEDICAL_PROVIDER_SUCCESS:
            return {
                ...omit(state, 'get_patient_selected_medical_provider_request', 'created_errors'),
                selected_medical_provider: action.response
            }
        case membersConstants.GET_PATIENT_SELECTED_MEDICAL_PROVIDER_FAILURE:
            return {
                ...omit(state, 'get_patient_selected_medical_provider_request'),
                created_errors: action.response
            };

        case membersConstants.UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                update_patient_selected_medical_provider_request: true
            };
        case membersConstants.UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_SUCCESS:
            return {
                ...omit(state, 'update_patient_selected_medical_provider_request', 'created_errors'),
                updated_medical_provider: action.response
            }
        case membersConstants.UPDATE_PATIENT_SELECTED_MEDICAL_PROVIDER_FAILURE:
            return {
                ...omit(state, 'get_patient_selected_medical_provider_request'),
                created_errors: action.response
            };

        case membersConstants.CREATE_PATIENT_MEDICAL_PROVIDER_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                create_patient_medical_provider_request: true
            };
        case membersConstants.CREATE_PATIENT_MEDICAL_PROVIDER_SUCCESS:
            return {
                ...omit(state, 'create_patient_medical_provider_request', 'created_errors'),
                created_medical_provider: action.response
            }
        case membersConstants.CREATE_PATIENT_MEDICAL_PROVIDER_FAILURE:
            return {
                ...omit(state, 'create_patient_medical_provider_request'),
                created_errors: action.response
            };

        case membersConstants.DELETE_PATIENT_MEDICAL_PROVIDER_REQUEST:
            return {
                ...omit(state, 'created_errors'),
                delete_patient_medical_provider_request: true
            };
        case membersConstants.DELETE_PATIENT_MEDICAL_PROVIDER_SUCCESS:
            return {
                ...omit(state, 'create_patient_medical_provider_request', 'created_errors'),
                deleted_medical_provider: action.response
            }
        case membersConstants.DELETE_PATIENT_MEDICAL_PROVIDER_FAILURE:
            return {
                ...omit(state, 'create_patient_medical_provider_request'),
                created_errors: action.response
            };

        case membersConstants.GET_ALL_ASSESSMENT_SUMMARY_REQUEST:
            return {
                ...omit(state, 'assessment_errors'),
                get_all_assessment_request: true
            };
        case membersConstants.GET_ALL_ASSESSMENT_SUMMARY_SUCCESS:
            return {
                ...omit(state, 'get_all_assessment_request', 'assessment_errors'),
                all_assessment: action.response
            }
        case membersConstants.GET_ALL_ASSESSMENT_SUMMARY_FAILURE:
            return {
                ...omit(state, 'get_all_assessment_request'),
                assessment_errors: action.response
            };

        case membersConstants.UPDATE_ASSESSMENT_SUMMARY_REQUEST:
            return {
                ...omit(state, 'assessment_errors'),
                update_assessment_request: true
            };
        case membersConstants.UPDATE_ASSESSMENT_SUMMARY_SUCCESS:
            return {
                ...omit(state, 'update_assessment_request', 'assessment_errors'),
                updated_assessment: action.response
            }
        case membersConstants.UPDATE_ASSESSMENT_SUMMARY_FAILURE:
            return {
                ...omit(state, 'update_assessment_request'),
                assessment_errors: action.response
            };

        case membersConstants.CREATE_ASSESSMENT_SUMMARY_REQUEST:
            return {
                ...omit(state, 'assessment_errors'),
                create_assessment_request: true
            };
        case membersConstants.CREATE_ASSESSMENT_SUMMARY_SUCCESS:
            return {
                ...omit(state, 'create_assessment_request', 'assessment_errors'),
                created_assessment: action.response
            }
        case membersConstants.CREATE_ASSESSMENT_SUMMARY_FAILURE:
            return {
                ...omit(state, 'create_assessment_request'),
                assessment_errors: action.response
            };


        case membersConstants.DELETE_MEMBER_REQUEST:
            return {
                ...state,
                delete_member_request: true
            };
        case membersConstants.DELETE_MEMBER_SUCCESS:
            return {
                ...omit(state, 'delete_member_request'),
            }
        case membersConstants.DELETE_MEMBER_FAILURE:
            return {
                ...omit(state, 'delete_member_request'),
                member_delete_errors: action.response
            };

        case membersConstants.GET_ALL_MEDICINE_REASON_REQUEST:
            return {
                ...omit(state, 'get_all_reasons_errors'),
                get_all_reason_request: true
            };
        case membersConstants.GET_ALL_MEDICINE_REASON_SUCCESS:
            return {
                ...omit(state, 'get_all_reason_request', 'get_all_reasons_errors'),
                reasons: action.response
            }
        case membersConstants.GET_ALL_MEDICINE_REASON_FAILURE:
            return {
                ...omit(state, 'create_assessment_request'),
                get_all_reasons_errors: action.response
            };

        case membersConstants.CREATE_MEDICINE_REASON_REQUEST:
            return {
                ...omit(state, 'reasons_errors'),
                create_reason_request: true
            };
        case membersConstants.CREATE_MEDICINE_REASON_SUCCESS:
            return {
                ...omit(state, 'create_reason_request', 'create_errors')
            }
        case membersConstants.CREATE_MEDICINE_REASON_FAILURE:
            return {
                ...omit(state, 'create_reason_request'),
                create_errors: action.response
            };

        case membersConstants.DELETE_MEDICINE_REASON_REQUEST:
            return {
                ...omit(state, 'reasons_errors'),
                delete_reason_request: true
            };
        case membersConstants.DELETE_MEDICINE_REASON_SUCCESS:
            return {
                ...omit(state, 'delete_reason_request'),
            }
        case membersConstants.DELETE_MEDICINE_REASON_FAILURE:
            return {
                ...omit(state, 'delete_reason_request'),
                reasons_errors: action.response
            };

        case membersConstants.UPDATE_MEDICINE_REASON_REQUEST:
            return {
                ...omit(state, 'reasons_errors'),
                update_reason_request: true
            };
        case membersConstants.UPDATE_MEDICINE_REASON_SUCCESS:
            return {
                ...omit(state, 'update_reason_request'),
            }
        case membersConstants.UPDATE_MEDICINE_REASON_FAILURE:
            return {
                ...omit(state, 'update_reason_request'),
                create_errors: action.response
            };

        case membersConstants.GET_ALL_MEDICATION_SETTINGS_REQUEST:
            return {
                ...omit(state, 'get_all_medication_settings_errors'),
                get_all_medication_settings_request: true
            };
        case membersConstants.GET_ALL_MEDICATION_SETTINGS_SUCCESS:
            return {
                ...omit(state, 'get_all_medication_settings_request', 'get_all_medication_settings_errors'),
                medication_settings: action.response
            }
        case membersConstants.GET_ALL_MEDICATION_SETTINGS_FAILURE:
            return {
                ...omit(state, 'get_all_medication_settings_request'),
                get_all_medication_settings_errors: action.response
            };

        case membersConstants.UPDATE_MEDICATION_SETTINGS_REQUEST:
            return {
                ...omit(state, 'update_medication_setting_error'),
                update_medication_setting_request: true
            };
        case membersConstants.UPDATE_MEDICATION_SETTINGS_SUCCESS:
            return {
                ...omit(state, 'update_medication_setting_request', 'update_medication_setting_error'),
                medication_settings: action.response
            }
        case membersConstants.UPDATE_MEDICATION_SETTINGS_FAILURE:
            return {
                ...omit(state, 'update_medication_setting_request', 'update_medication_setting_success'),
                update_medication_setting_error: action.response
            };

        case membersConstants.DELETE_MEDICATION_SETTINGS_REQUEST:
            return {
                ...omit(state, 'delete_medication_setting_error'),
                delete_medication_setting_request: true
            };
        case membersConstants.DELETE_MEDICATION_SETTINGS_SUCCESS:
            return {
                ...omit(state, 'delete_medication_setting_request', 'delete_medication_setting_error'),
                delete_medication_settings_success: true
            }
        case membersConstants.DELETE_MEDICATION_SETTINGS_FAILURE:
            return {
                ...omit(state, 'delete_medication_setting_request', 'delete_medication_setting_success'),
                delete_medication_setting_error: action.response
            };

        case membersConstants.UPDATE_MANAGE_DEPENDENT_REQUEST:
            return {
                ...state,
                update_manage_dependent_email_request: true
            };
        case membersConstants.UPDATE_MANAGE_DEPENDENT_SUCCESS:
            return {
                ...omit(state, 'update_manage_dependent_email_request'),
                updated_manage_dependent_email: action.response
            }
        case membersConstants.UPDATE_MANAGE_DEPENDENT_FAILURE:
            return {
                ...omit(state, 'update_manage_dependent_email_request'),
                manage_dependent_errors: action.response
            };

        case membersConstants.UPDATE_MANAGE_PARENT_REQUEST:
            return {
                ...state,
                update_manage_parent_email_request: true
            };
        case membersConstants.UPDATE_MANAGE_PARENT_SUCCESS:
            return {
                ...omit(state, 'update_manage_parent_email_request'),
                updated_manage_parent_email: action.response
            }
        case membersConstants.UPDATE_MANAGE_PARENT_FAILURE:
            return {
                ...omit(state, 'update_manage_parent_email_request'),
                manage_parent_errors: action.response
            };

        default:
            return state;
    }
}
