export const noticesConstants = {


    CLEAR_NOTICES_PROPS: 'CLEAR_NOTICES_PROPS',

    GET_ALL_NOTICES_REQUEST: 'GET_ALL_NOTICES_REQUEST',
    GET_ALL_NOTICES_SUCCESS: 'GET_ALL_NOTICES_SUCCESS',
    GET_ALL_NOTICES_FAILURE: 'GET_ALL_NOTICES_FAILURE',

    UPDATE_NOTICES_REQUEST: 'UPDATE_NOTICE_REQUEST',
    UPDATE_NOTICES_SUCCESS: 'UPDATE_NOTICE_SUCCESS',
    UPDATE_NOTICES_FAILURE: 'UPDATE_NOTICE_FAILURE',

    REMOVE_NOTICE_REQUEST: 'REMOVE_NOTICE_REUEST',
    REMOVE_NOTICE_SUCCESS: 'REMOVE_NOTICE_SUCCESS',
    REMOVE_NOTICE_FAILURE: 'REMOVE_NOTICE_FAILURE',

    GET_NOTICE_REQUEST: 'GET_NOTICE_REQUEST',
    GET_NOTICE_SUCCESS: 'GET_NOTICE_SUCCESS',
    GET_NOTICE_FAILURE: 'GET_NOTICE_FAILURE',

    ADD_NOTICE_REQUEST: 'ADD_NOTICE_REQUEST',
    ADD_NOTICE_SUCCESS: 'ADD_NOTICE_SUCCESS',
    ADD_NOTICE_FAILURE: 'ADD_NOTICE_FAILURE',
};
