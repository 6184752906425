import { careTeamConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';



const initialState = {};

export function careTeam(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;
            
        case careTeamConstants.CLEAR_CARE_TEAM_PROPS:
            return {
                ...omit(state, [action.payload])
            }

        case careTeamConstants.GET_ALL_CARE_PERSONS_REQUEST:
            return {
                ...state,
                get_all_care_persons_request: true
            }
        case careTeamConstants.GET_ALL_CARE_PERSONS_SUCCESS:
            return {
                ...omit(state, 'get_all_care_persons_request'),
                care_persons: action.response
            }
        case careTeamConstants.GET_ALL_CARE_PERSONS_FAILURE:
            return {
                ...omit(state, 'get_all_care_persons_request')
            }

        case careTeamConstants.ASSIGN_CARE_PERSON_REQUEST:
            return {
                ...state,
                assign_care_persons_request: true
            }
        case careTeamConstants.ASSIGN_CARE_PERSON_SUCCESS:
            return {
                ...omit(state, 'assign_care_persons_request', 'created_errors'),
                assigned_care_person: true
            }
        case careTeamConstants.ASSIGN_CARE_PERSON_FAILURE:
            return {
                ...omit(state, 'assign_care_persons_request'),
                created_errors: action.response
            }

        case careTeamConstants.REVOKE_CARE_PERSON_REQUEST:
            return {
                ...state,
                revoke_care_persons_request: true
            }
        case careTeamConstants.REVOKE_CARE_PERSON_SUCCESS:
            return {
                ...omit(state, 'revoke_care_persons_request')
            }
        case careTeamConstants.REVOKE_CARE_PERSON_FAILURE:
            return {
                ...omit(state, 'revoke_care_persons_request')
            }

        case careTeamConstants.GET_CARE_PERSON_REQUEST:
            return {
                ...state,
                get_care_person_request: true
            };
        case careTeamConstants.GET_CARE_PERSON_SUCCESS:
            return {
                ...omit(state, 'get_care_person_request'),
                selected_care_person: action.response
            }
        case careTeamConstants.GET_CARE_PERSON_FAILURE:
            return {
                ...omit(state, 'get_care_person_request'),
                create_error: action.response
            }

        case careTeamConstants.UPDATE_CARE_PERSON_REQUEST:
            return {
                ...state,
                update_care_person_request: true,
                loading: true
            };
        case careTeamConstants.UPDATE_CARE_PERSON_SUCCESS:
            return {
                ...omit(state, 'update_care_person_request', 'loading'),
                updated_care_person: action.response
            }
        case careTeamConstants.UPDATE_CARE_PERSON_FAILURE:
            return {
                ...omit(state, 'update_care_person_request', 'loading'),
                create_error: action.response
            }

        case careTeamConstants.CREATE_CARE_PERSON_REQUEST:
            return {
                ...state,
                create_care_person_request: true,
                loading: true
            };
        case careTeamConstants.CREATE_CARE_PERSON_SUCCESS:
            return {
                ...omit(state, 'create_care_person_request', 'loading'),
                created_care_person: action.response
            }
        case careTeamConstants.CREATE_CARE_PERSON_FAILURE:
            return {
                ...omit(state, 'create_care_person_request', 'loading'),
                create_error: action.response
            }

        case careTeamConstants.REMOVE_CARE_PERSON_REQUEST:
            return {
                ...state,
                remove_care_person_request: true
            };
        case careTeamConstants.REMOVE_CARE_PERSON_SUCCESS:
            return {
                ...omit(state, 'remove_care_person_request'),
            }
        case careTeamConstants.REMOVE_CARE_PERSON_FAILURE:
            return {
                ...omit(state, 'remove_care_person_request'),
            }

        case careTeamConstants.GET_ASSIGNED_MEMBERS_REQUEST:
            return {
                ...state,
                get_assigned_members_request: true
            };
        case careTeamConstants.GET_ASSIGNED_MEMBERS_SUCCESS:
            return {
                ...omit(state, 'get_assigned_members_request'),
                assigned_members: action.response
            }
        case careTeamConstants.GET_ASSIGNED_MEMBERS_FAILURE:
            return {
                ...omit(state, 'get_assigned_members_request'),
            }


        default:
            return state;
    }
}
