import { appointmentConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';

const initialState = {};

export function appointment(state = initialState, action) {
    switch (action.type) {
        case authConstants.LOGOUT_SUCCESS :
            return initialState;
        
        case appointmentConstants.CLEAR_PROPS:
            return {
                ...omit(state, [action.payload])
            }

            case appointmentConstants.GET_ALL_REQUEST:
            return {
                ...state,
                get_all_appointments_request: true
            };
        case appointmentConstants.GET_ALL_SUCCESS:
            return {
                ...omit(state, 'get_all_appointments_request'),
                appointments: action.response
            }
        case appointmentConstants.GET_ALL_FAILURE:
            return {
                ...omit(state, 'get_all_appointments_request'),
            }

        case appointmentConstants.GET_REQUEST:
            return {
                ...state,
                get_appointment_request: true
            };
        case appointmentConstants.GET_SUCCESS:
            return {
                ...omit(state, 'appointment_get_request'),
                selected_appointment: action.response
            }
        case appointmentConstants.GET_FAILURE:
            return {
                ...omit(state, 'appointment_get_request'),
            }

        case appointmentConstants.UPDATE_REQUEST:
            return {
                ...state,
                appointment_update_request: true,
                loading: true
            };
        case appointmentConstants.UPDATE_SUCCESS:
            return {
                ...omit(state, 'appointment_update_request', 'loading'),
                updated_appointment: action.response
            }
        case appointmentConstants.UPDATE_FAILURE:
            return {
                ...omit(state, 'appointment_update_request', 'loading'),
                create_error: action.response
            }

        case appointmentConstants.ADD_REQUEST:
            return {
                ...state,
                appointment_create_request: true,
                loading: true
            };
        case appointmentConstants.ADD_SUCCESS:
            return {
                ...omit(state, 'appointment_create_request', 'loading'),
                created_appointment: action.response
            }
        case appointmentConstants.ADD_FAILURE:
            return {
                ...omit(state, 'appointment_create_request', 'loading'),
                create_error: action.response
            }

        case appointmentConstants.REMOVE_REQUEST:
            return {
                ...state,
                appointment_remove_request: true
            };
        case appointmentConstants.REMOVE_SUCCESS:
            return {
                ...omit(state, 'appointment_remove_request'),
                removed_appointment: action.response
            }
        case appointmentConstants.REMOVE_FAILURE:
            return {
                ...omit(state, 'appointment_remove_request'),
            }

        case appointmentConstants.RESCHEDULE_REQUEST:
            return {
                ...state,
                appointment_update_request: true
            };
        case appointmentConstants.CANCEL_REQUEST:
            return {
                ...state,
                appointment_update_request: true
            }


        default:
            return state;
    }
}
