import { messageCenterConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';

const initialState = {
    messages : {},
    selected_thread_id : '',
    typed_message :{},
    threads : {
        threads: [],
        meta  : {}
    },
    selected_care_person_data : {},
    selected_patient_data : {},
};

export function messageCenter(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case messageCenterConstants.GET_THREAD_REQUEST: {
            return {
                ...omit(state, ['thread_request_success', 'thread_request_failure']),
                get_thread_request: true
            };
        }
        case messageCenterConstants.GET_THREAD_SUCCESS: {
            let threads  = {threads: [], meta  : {}};
            action.response.data && action.response.data.map((item ) => {
                if( !item['label'] ){
                    let participants = []
                    let participants_roles = [];
                    item['to_users'] = [];
                    item['participants'] && item['participants'].map((participant) => {
                        if(! participant['is_logged_in_user'] ){
                            item['to_users'].push( participant );
                            let name = participant['user_name'];
                            if( participant.is_deleted_user  ){
                                name = `${participant['user_name']} (deleted)`;
                            }
                            else{
                                item['active_user_exists'] = 1;
                            }
                            participants.push( name );
                            if( participant['user_type'] === 'CarePerson' ){
                                let role_label = 'Nurse';
                                if( participant.care_person_role && participant.care_person_role.label ){
                                    role_label= participant.care_person_role.label;
                                }
                                participants_roles.push( role_label );
                            }
                            else{
                                participants_roles.push( participant['user_type'] );
                            }

                        }
                        return participant;
                    })
                    item['label'] = participants.join();
                    item['role_label'] = participants_roles.join();
                }
                threads['threads'].push(item);
                return item;
            });
            threads['meta'] = action.response.meta;
            return {
                ...omit(state, ['get_thread_request', 'thread_request_failure']),
                threads: threads,
                thread_request_success: true,
            };
        }
        case messageCenterConstants.GET_THREAD_FAILURE: {
            return {
                ...omit(state, ['get_thread_request','thread_request_success']),
                get_thread_errors : action.response,
                thread_request_failure: true,
            };
        }
        case messageCenterConstants.GET_MESSAGE_REQUEST: {
            return {
                ...omit(state, ['message_request_success', 'message_request_failure']),
                get_message_request: true
            };
        }
        case messageCenterConstants.GET_MESSAGE_SUCCESS: {
            let messages  = state.messages;
            if(! messages[action.thread_id] ){
                messages[action.thread_id] = { messages : [],  meta : {} };
            }
            if( action.response.data  ) {
                messages[action.thread_id]['messages'] = action.response.data;
            }
            if( action.response.meta  ) {
                messages[action.thread_id]['meta'] = action.response.meta;
            }
            return {
                ...omit(state, ['get_message_request', 'message_request_failure']),
                messages: messages,
                message_request_success: true
            };
        }
        case messageCenterConstants.GET_MESSAGE_FAILURE: {
            return {
                ...omit(state, ['get_message_request', 'message_request_success']),
                threads: action.response,
                message_request_failure: true
            };
        }


        case messageCenterConstants.GET_RECENT_MESSAGE_REQUEST: {
            return {
                ...omit(state, ['message_recent_request_success', 'message_recent_request_failure']),
                get_recent_message_request: true
            };
        }
        case messageCenterConstants.GET_RECENT_MESSAGE_SUCCESS: {
            return {
                ...omit(state, ['get_recent_message_request', 'message_recent_request_failure']),
                message_recent_request_success: true,
                recent_message :action.response
            };
        }
        case messageCenterConstants.GET_RECENT_MESSAGE_FAILURE: {
            return {
                ...omit(state, ['get_recent_message_request', 'message_recent_request_success']),
                message_recent_request_failure: true,
                recent_message_error: action.response
            };
        }


        case messageCenterConstants.CREATE_THREAD_REQUEST: {
            return {
                ...omit(state, ['create_thread_failure', 'create_thread_success']),
                create_thread_request: true
            };
        }
        case messageCenterConstants.CREATE_THREAD_SUCCESS: {
            return {
                ...omit(state, ['create_thread_request', 'create_thread_failure']),
                create_thread_success: true,
                selected_thread_id : action.response.mc_thread_id
            };
        }
        case messageCenterConstants.CREATE_THREAD_FAILURE: {
            return {
                ...omit(state, ['create_thread_request', 'create_thread_success']),
                create_thread_failure: true
            };
        }
        case messageCenterConstants.CREATE_MESSAGE_REQUEST: {
            return {
                ...omit(state, ['message_create_success', 'message_create_failure']),
                create_message_request: true
            };
        }
        case messageCenterConstants.CREATE_MESSAGE_SUCCESS: {
            let { typed_message } = state;
            if(action.payload.status && action.payload.status.clear_message) {
                typed_message[action.payload.thread_id] = '';
            }
            return {
                ...omit(state, ['create_message_request', 'message_create_failure']),
                message_create_success: true,
                typed_message
            };
        }
        case messageCenterConstants.CREATE_MESSAGE_FAILURE: {
            return {
                ...omit(state, ['create_message_request', 'message_create_success']),
                message_create_failure: true
            };
        }
        case messageCenterConstants.SELECT_THREAD :{
            return {
                ...omit(state),
                selected_thread_id: action.payload.thread_id
            }
        }
        case messageCenterConstants.SET_TYPED_MESSAGE: {
            let { typed_message } = state;
            typed_message[action.payload.thread_id] = action.payload.message;
            return {
                ...state,
                typed_message
            }
        }

        case messageCenterConstants.CREATE_FILE_ATTACHEMENT_REQUEST:

            return {
                ...state,
                create_file_attachement_request: true,
            };
        case messageCenterConstants.CREATE_FILE_ATTACHEMENT_SUCCESS:
            return {
                ...omit(state, 'create_file_attachement_request'),
                file_attachement_created: action.response
            };
        case messageCenterConstants.CREATE_FILE_ATTACHEMENT_FAILURE:
            return {
                ...omit(state, 'create_file_attachement_request'),
                created_errors: action.response
            };

        case messageCenterConstants.GET_FILE_ATTACHEMENT_REQUEST:
            return {
                ...state,
                get_file_attachement_request: true,
            };
        case messageCenterConstants.GET_FILE_ATTACHEMENT_SUCCESS:
            return {
                ...omit(state, 'get_file_attachement_request'),
                files: action.response
            };
        case messageCenterConstants.GET_FILE_ATTACHEMENT_FAILURE:
            return {
                ...omit(state, 'get_file_attachement_request'),
                created_errors: action.response
            };

        case messageCenterConstants.DOWNLOAD_FILE_ATTACHEMENT_REQUEST:
            return {
                ...state,
                download_file_attachement_request: true,
            };
        case messageCenterConstants.DOWNLOAD_FILE_ATTACHEMENT_SUCCESS:
            return {
                ...omit(state, 'download_file_attachement_request'),
                downloaded_file: action.response
            };
        case messageCenterConstants.DOWNLOAD_FILE_ATTACHEMENT_FAILURE:
            return {
                ...omit(state, 'download_file_attachement_request'),
                created_errors: action.response
            };

        case messageCenterConstants.GET_SHARED_FILE_REQUEST:
            return {
                ...state,
                shared_file_request: true,
            };
        case messageCenterConstants.GET_SHARED_FILE_SUCCESS:
            return {
                ...omit(state, 'shared_file_request'),
                shared_files: action.response
            };
        case messageCenterConstants.GET_SHARED_FILE_FAILURE:
            return {
                ...omit(state, 'shared_file_request'),
                created_errors: action.response
            };

        case messageCenterConstants.MESSAGE_CENTER_CLEAR:
            return {...omit(state, [action.payload])};
        default:
            return state;
    }
}
