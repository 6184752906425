import { lookupsConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';

const initialState = {};

export function lookups(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case lookupsConstants.GET_LOOKUPS_REQUEST:
            return {
                ...state,
            };
        case lookupsConstants.GET_LOOKUPS_SUCCESS:
            let lookup_data = {
                marital_statuses: [], ethnicities: [], preferred_languages: [], states: [], care_person_roles: [], company_statuses: [],
                industries: [], genders: [], medication_dosage_forms: [], medication_frequencies: [], patient_medication_statuses: [],
                medication_types: [], medication_periods: [], medical_condition_statuses: [], nursing_credentials: [], surgeries: [], user_types: [],
                allergic_substances: [], call_request_timeslots: [], call_request_reasons: [], resource_categories: [], shared_with_user_types: [],
                subscriptions: [], subscription_types: [], medical_provider_specialities: [], timezones: [], task_types:[],
                medication_frequency_types: [], race:[], communication_types: [], task_recurrences: [], task_statuses : [],
                event_types: [], event_communication_types: [], reminder_times: [], event_statuses: [],
            };
            if (action.response) {
                if (action.response && action.response.marital_statuses) {
                    action.response.marital_statuses.map(item => (
                        lookup_data.marital_statuses.push({ ...item, value: item.slug, label: item.label, type: 'marital_status' })
                    ));
                }
                if (action.response && action.response.ethnicities) {
                    action.response.ethnicities.map(item => (
                        lookup_data.ethnicities.push({ ...item, value: item.slug, label: item.label, type: 'ethnicity' })
                    ));
                }

                if (action.response && action.response.preferred_languages) {
                    action.response.preferred_languages.map(item => (
                        lookup_data.preferred_languages.push({ ...item, value: item.slug, label: item.label, type: 'preferred_language' })
                    ));
                }
                if (action.response && action.response.states) {
                    action.response.states.map(item => (
                        lookup_data.states.push({ ...item, value: item.state_id, label: item.name, type: 'state' })
                    ));

                }
                if (action.response && action.response.care_person_roles) {
                    action.response.care_person_roles.map(item => (
                        lookup_data.care_person_roles.push({ ...item, value: item.slug, label: item.label, type: 'care_person_role' })
                    ));
                }
                if (action.response && action.response.company_statuses) {
                    action.response.company_statuses.map(item => (
                        lookup_data.company_statuses.push({ ...item, value: item.slug, label: item.label, type: 'company_statuses' })
                    ));
                }

                if (action.response && action.response.industries) {
                    action.response.industries.map(item => (
                        lookup_data.industries.push({ ...item, value: item.slug, label: item.label, type: 'industries' })
                    ));
                }
                if (action.response && action.response.medical_condition_statuses) {
                    action.response.medical_condition_statuses.map(item => (
                        lookup_data.medical_condition_statuses.push({ ...item, value: item.slug, label: item.label, medical_condition_status_id: item.medical_condition_status_id })
                    ));
                }

                if (action.response && action.response.genders) {
                    action.response.genders.map(item => (
                        lookup_data.genders.push({ ...item, value: item.slug, label: item.label, type: 'genders' })
                    ));
                }
                if (action.response && action.response.medication_dosage_forms) {
                    action.response.medication_dosage_forms.map(item => (
                        lookup_data.medication_dosage_forms.push({ ...item, value: item.slug, label: item.label, type: 'medication_dosage_forms' })
                    ));
                }
                if (action.response && action.response.medication_frequencies) {
                    action.response.medication_frequencies.map(item => (
                        lookup_data.medication_frequencies.push({ ...item, value: item.slug, label: item.label, type: 'medication_frequencies' })
                    ));
                }
                if (action.response && action.response.patient_medication_statuses) {
                    action.response.patient_medication_statuses.map(item => (
                        lookup_data.patient_medication_statuses.push({ ...item, value: item.slug, label: item.label, type: 'patient_medication_statuses' })
                    ));
                }
                if (action.response && action.response.medication_types) {
                    action.response.medication_types.map(item => (
                        lookup_data.medication_types.push({ ...item, value: item.slug, label: item.label, type: 'medication_types' })
                    ));
                }
                if (action.response && action.response.medication_periods) {
                    action.response.medication_periods.map(item => (
                        lookup_data.medication_periods.push({ ...item, value: item.slug, label: item.label, type: 'medication_periods' })
                    ));
                }
                if (action.response.nursing_credentials) {
                    action.response.nursing_credentials.map(item => (
                        lookup_data.nursing_credentials.push({ ...item, value: item.slug, label: item.label, type: 'nursing_credential' })
                    ));
                }

                if (action.response && action.response.surgeries) {
                    action.response.surgeries.map(item => (
                        lookup_data.surgeries.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response.user_types) {
                    action.response.user_types.map(item => (
                        lookup_data.user_types.push({ ...item, value: item.slug, label: item.label, type: 'user_types' })
                    ));
                }
                if (action.response && action.response.allergic_substances) {
                    action.response.allergic_substances.map((item) => (
                        lookup_data.allergic_substances.push({ value: item.slug, label: item.label, allergic_substance_id: item.allergic_substance_id, slug: item.slug })
                    ));
                }
                if (action.response && action.response.call_request_timeslots) {
                    action.response.call_request_timeslots.map(item => (
                        lookup_data.call_request_timeslots.push({ ...item, value: item.slug, label: item.label })
                    ));
                }
                if (action.response && action.response.call_request_reasons) {
                    action.response.call_request_reasons.map(item => (
                        lookup_data.call_request_reasons.push({ ...item, value: item.slug, label: item.label })
                    ));
                }
                if (action.response && action.response.resource_categories) {
                    action.response.resource_categories.map(item => (
                        lookup_data.resource_categories.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response && action.response.shared_with_user_types) {
                    action.response.shared_with_user_types.map(item => (
                        lookup_data.shared_with_user_types.push({ ...item, value: item.slug, label: item.label })
                    ));
                }
                if (action.response && action.response.medical_provider_specialities) {
                    action.response.medical_provider_specialities.map(item => (
                        lookup_data.medical_provider_specialities.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response && action.response.subscriptions) {
                    action.response.subscriptions.map(item => (
                        lookup_data.subscriptions.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response && action.response.subscription_types) {
                    action.response.subscription_types.map(item => (
                        lookup_data.subscription_types.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response && action.response.timezones) {
                    action.response.timezones.map(item => (
                        lookup_data.timezones.push({ ...item, value: item.timezone_id, label: item.name })
                    ));
                }

                if (action.response && action.response.task_types) {
                    action.response.task_types.map(item => (
                        lookup_data.task_types.push({ ...item, value: item.slug, label: item.label })
                    ));
                }


                if (action.response && action.response.medication_frequency_types) {
                    action.response.medication_frequency_types.map(item => (
                        lookup_data.medication_frequency_types.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response.races) {
                    action.response.races.map(item => (
                        lookup_data.race.push({ ...item, value: item.slug, label: item.label, type: 'race' })
                    ));
                }


                if (action.response.communication_types) {
                    action.response.communication_types.map(item => (
                        lookup_data.communication_types.push({ ...item, value: item.slug, label: item.label, type: 'preferred_communications' })
                    ));
                }
                if (action.response.task_recurrences) {
                    let custom = {value: 'custom', label:'Custom', slug: 'custom'};
                    action.response.task_recurrences.map(item => (
                        lookup_data.task_recurrences.push({ ...item, value: item.slug, label: item.label, type: 'task_recurrences' })
                    ));
                    lookup_data.task_recurrences.push(custom)
                }
                if (action.response.task_statuses) {
                    action.response.task_statuses.map(item => (
                        lookup_data.task_statuses.push({ ...item, value: item.slug, label: item.label, type: 'task_statuses' })
                    ));
                }

                if (action.response && action.response.event_types) {
                    action.response.event_types.map(item => (
                        lookup_data.event_types.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response && action.response.event_communication_types) {
                    action.response.event_communication_types.map(item => (
                        lookup_data.event_communication_types.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response && action.response.reminder_times) {
                    action.response.reminder_times.map(item => (
                        lookup_data.reminder_times.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

                if (action.response && action.response.event_statuses) {
                    action.response.event_statuses.map(item => (
                        lookup_data.event_statuses.push({ ...item, value: item.slug, label: item.label })
                    ));
                }

            }
            return {
                ...state,
                lookups: lookup_data
            }
        case lookupsConstants.GET_LOOKUPS_FAILURE:
            return {
                ...state,
                created_errors: action.response
            };

        case lookupsConstants.CLEAR_LOOKUPS:
            return {
                ...omit(state, [action.payload])
            }
        default:
            return state;
    }
}
