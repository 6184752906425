export const appointmentConstants = {


    CLEAR_PROPS: 'APPOINTMENT_CLEAR_PROPS',

    GET_ALL_REQUEST: 'APPOINTMENT_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'APPOINTMENT_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'APPOINTMENT_GET_ALL_FAILURE',

    UPDATE_REQUEST: 'APPOINTMENT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'APPOINTMENT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'APPOINTMENT_UPDATE_FAILURE',

    REMOVE_REQUEST: 'APPOINTMENT_REMOVE_REUEST',
    REMOVE_SUCCESS: 'APPOINTMENT_REMOVE_SUCCESS',
    REMOVE_FAILURE: 'APPOINTMENT_REMOVE_FAILURE',

    GET_REQUEST: 'APPOINTMENT_GET_REQUEST',
    GET_SUCCESS: 'APPOINTMENT_GET_SUCCESS',
    GET_FAILURE: 'APPOINTMENT_GET_FAILURE',

    ADD_REQUEST: 'APPOINTMENT_ADD_REQUEST',
    ADD_SUCCESS: 'APPOINTMENT_ADD_SUCCESS',
    ADD_FAILURE: 'APPOINTMENT_ADD_FAILURE',

    RESCHEDULE_REQUEST: 'APPOINTMENT_RESCHEDULE_REQUEST',
    CANCEL_REQUEST: 'APPOINTMENT_CANCEL_REQUEST',
};
