import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';


import rootReducer from 'js/reducers';
import { history } from 'js/helpers';


const loggerMiddleware = createLogger();

const analyticsMiddleware = () => next => action => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: action.type,
		payload: action.payload
	});
	return next(action);
};

let middlewares = [
	analyticsMiddleware,
	thunk,
	routerMiddleware(history),
];

if (process.env.REACT_APP_ENV === 'development') {
	middlewares.push(loggerMiddleware);
}


export const configureStore = () => {

    const store = createStore(
        rootReducer(history),
        applyMiddleware(...middlewares)
    )

    return store;
}
