export const patientConstants = {
    REQUEST_CALL_REQUEST: 'REQUEST_CALL_REQUEST',
    REQUEST_CALL_SUCCESS: 'REQUEST_CALL_SUCCESS',
    REQUEST_CALL_FAILURE: 'REQUEST_CALL_FAILURE',

    PAM_SURVERY_REQUEST: 'PAM_SURVERY_REQUEST',
    PAM_SURVERY_SUCCESS: 'PAM_SURVERY_SUCCESS',
    PAM_SURVERY_FAILURE: 'PAM_SURVERY_FAILURE',

    SUBMIT_PAM_SURVERY_REQUEST: 'SUBMIT_PAM_SURVERY_REQUEST',
    SUBMIT_PAM_SURVERY_SUCCESS: 'SUBMIT_PAM_SURVERY_SUCCESS',
    SUBMIT_PAM_SURVERY_FAILURE: 'SUBMIT_PAM_SURVERY_FAILURE',

    CLEAR_PATIENT : 'CLEAR_PATIENT'
};
