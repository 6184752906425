import { patientConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';

const initialState = {};

export function patient(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case patientConstants.REQUEST_CALL_REQUEST:
            return {
                ...state,
                request_call_request: true
            };
        case patientConstants.REQUEST_CALL_SUCCESS:
            return {
                ...omit(state, 'request_call_request'),
                request_call: action.response
            }
        case patientConstants.REQUEST_CALL_FAILURE:
            return {
                ...omit(state, 'request_call_request'),
                created_errors: action.response
            };

        case patientConstants.PAM_SURVERY_REQUEST:
            return {
                ...state,
                pam_survery_request: true
            };
        case patientConstants.PAM_SURVERY_SUCCESS:
            return {
                ...omit(state, 'request_call_request'),
                pam_survery: action.response
            }
        case patientConstants.PAM_SURVERY_FAILURE:
            return {
                ...omit(state, 'request_call_request'),
                pam_survery_errors: action.response
            };


        case patientConstants.SUBMIT_PAM_SURVERY_REQUEST:
            return {
                ...state,
                submit_pam_request: true
            };
        case patientConstants.SUBMIT_PAM_SURVERY_SUCCESS:
            return {
                ...omit(state, 'submit_pam_request'),
                submit_pam_success: action.response
            }
        case patientConstants.SUBMIT_PAM_SURVERY_FAILURE:
            return {
                ...omit(state, 'submit_pam_request'),
                submit_pam_errors: action.response
            };

        case patientConstants.CLEAR_PATIENT:
            return {
                ...omit(state, [action.payload])
            }
        default:
            return state;
    }
}
