export const resourcesConstants = {
    CREATE_RESOURCE_REQUEST: 'CREATE_RESOURCE_REQUEST',
    CREATE_RESOURCE_SUCCESS: 'CREATE_RESOURCE_SUCCESS',
    CREATE_RESOURCE_FAILURE: 'CREATE_RESOURCE_FAILURE',

    GET_ALL_RESOURCE_REQUEST: 'GET_ALL_RESOURCE_REQUEST',
    GET_ALL_RESOURCE_SUCCESS: 'GET_ALL_RESOURCE_SUCCESS',
    GET_ALL_RESOURCE_FAILURE: 'GET_ALL_RESOURCE_FAILURE',

    GET_RESOURCE_REQUEST: 'GET_RESOURCE_REQUEST',
    GET_RESOURCE_SUCCESS: 'GET_RESOURCE_SUCCESS',
    GET_RESOURCE_FAILURE: 'GET_RESOURCE_FAILURE',

    UPDATE_RESOURCE_REQUEST: 'UPDATE_RESOURCE_REQUEST',
    UPDATE_RESOURCE_SUCCESS: 'UPDATE_RESOURCE_SUCCESS',
    UPDATE_RESOURCE_FAILURE: 'UPDATE_RESOURCE_FAILURE',

    REMOVE_SELECTED_RESOURCE_REQUEST: 'REMOVE_SELECTED_RESOURCE_REQUEST',
    REMOVE_SELECTED_RESOURCE_SUCCESS: 'REMOVE_SELECTED_RESOURCE_SUCCESS',
    REMOVE_SELECTED_RESOURCE_FAILURE: 'REMOVE_SELECTED_RESOURCE_FAILURE',

    TOOGLE_FAVORITE_RESOURCE_REQUEST: 'TOOGLE_FAVORITE_RESOURCE_REQUEST',
    TOOGLE_FAVORITE_RESOURCE_SUCCESS: 'TOOGLE_FAVORITE_RESOURCE_SUCCESS',
    TOOGLE_FAVORITE_RESOURCE_FAILURE: 'TOOGLE_FAVORITE_RESOURCE_FAILURE',

    CLEAR_RESOURCE_PROPS: 'CLEAR_RESOURCE_PROPS',
};