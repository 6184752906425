export const loggingConstants = {
    GET_LOGS_REQUEST: 'GET_LOGS_REQUEST',
    GET_LOGS_SUCCESS: 'GET_LOGS_SUCCESS',
    GET_LOGS_FAILURE: 'GET_LOGS_FAILURE',

    GET_LOG_TYPE_REQUEST: 'GET_LOG_TYPE_REQUEST',
    GET_LOG_TYPE_SUCCESS: 'GET_LOG_TYPE_SUCCESS',
    GET_LOG_TYPE_FAILURE: 'GET_LOG_TYPE_FAILURE',

    DOWNLOAD_REQUEST: 'LOGGGING_DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'LOGGGING_DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE: 'LOGGGING_DOWNLOAD_FAILURE',
};
