import { carePlansConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';



const initialState = {};

export function carePlans(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case carePlansConstants.GET_ALL_CARE_PLANS_REPOSITORY_REQUEST:
            return {
                ...state,
                care_plan_request: true,
            }
        case carePlansConstants.GET_ALL_CARE_PLANS_REPOSITORY_SUCCESS:
            return {
                ...omit(state, 'care_plan_request'),
                care_plan_success: true,
                care_plans_list: action.response
            }
        case carePlansConstants.GET_ALL_CARE_PLANS_REPOSITORY_FAILURE:
            return {
                ...omit(state, 'care_plan_request', 'care_plan_success'),
                care_plan_failure: true,
                created_error: action.response
            }

        case carePlansConstants.CREATE_CARE_PLANS_REPOSITORY_REQUEST:
            return {
                ...state,
                create_request: true
            }
        case carePlansConstants.CREATE_CARE_PLANS_REPOSITORY_SUCCESS:
            return {
                ...omit(state, 'create_request', 'create_failure'),
                created: true,
                created_item: action.response
            }
        case carePlansConstants.CREATE_CARE_PLANS_REPOSITORY_FAILURE:
            return {
                ...omit(state, 'create_request', 'created'),
                create_failure: true,
                created_error: action.response
            }

        case carePlansConstants.UPDATE_CARE_PLANS_REPOSITORY_REQUEST:
            return {
                ...state,
                update_request: true
            }
        case carePlansConstants.UPDATE_CARE_PLANS_REPOSITORY_SUCCESS:
            return {
                ...omit(state, 'update_request', 'update_error'),
                update_success: true,
                updated_item: action.response
            }
        case carePlansConstants.UPDATE_CARE_PLANS_REPOSITORY_FAILURE:
            return {
                ...omit(state, 'update_request', 'update_success'),
                update_error: true,
                created_error: action.response
            }
        case carePlansConstants.GET_CARE_PLAN_REPOSITORY_REQUEST:
            return {
                ...state,
                get_care_plan_repo_req: true,
            }
        case carePlansConstants.GET_CARE_PLAN_REPOSITORY_SUCCESS:
            return {
                ...omit(state, 'get_care_plan_repo_req', 'delete_failure'),
                get_care_plan_repo_success: true,
                get_care_plan: action.response
            }
        case carePlansConstants.GET_CARE_PLAN_REPOSITORY_FAILURE:
            return {
                ...omit(state, 'get_care_plan_repo_req', 'get_care_plan_repo_success'),
                get_care_plan_repo_failure: true,
                created_error: action.response
            }


        case carePlansConstants.DELETE_CARE_PLANS_REPOSITORY_REQUEST:
            return {
                ...state,
                delete_request: true,
            }
        case carePlansConstants.DELETE_CARE_PLANS_REPOSITORY_SUCCESS:
            return {
                ...omit(state, 'delete_request', 'delete_failure'),
                delete_success: true,
                deleted_txt: action.response
            }
        case carePlansConstants.DELETE_CARE_PLANS_REPOSITORY_FAILURE:
            return {
                ...omit(state, 'delete_request', 'delete_success'),
                delete_failure: true,
                created_error: action.response
            }

        case carePlansConstants.GET_PATIENT_CARE_PLAN_REQUEST:
            return {
                ...state,
                patient_care_plan_req: true
            }
        case carePlansConstants.GET_PATIENT_CARE_PLAN_SUCCESS:
            return {
                ...omit(state, 'patient_care_plan_req'),
                patient_care_plan_success: true,
                patient_care_plan_list: action.response
            }//patient_care_plan_success,patient_care_plan_failure
        case carePlansConstants.GET_PATIENT_CARE_PLAN_FAILURE:
            return {
                ...omit(state, 'patient_care_plan_req'),
                patient_care_plan_failure: true,
                create_failure: action.response
            }
        case carePlansConstants.CREATE_PATIENT_CARE_PLAN_REQUEST:
            return {
                ...state,
                create_patient_care_plan_req: true
            }
        case carePlansConstants.CREATE_PATIENT_CARE_PLAN_SUCCESS:
            return {
                ...omit(state, 'create_patient_care_plan_req'),
                patient_care_plan_created: true,
                created_care_plan: action.response
            }//patient_care_plan_created_failure,patient_care_plan_created
        case carePlansConstants.CREATE_PATIENT_CARE_PLAN_FAILURE:
            return {
                ...omit(state, 'create_patient_care_plan_req', 'patient_care_plan_created'),
                patient_care_plan_created_failure: false,
                created_error: action.response
            }

        case carePlansConstants.GET_PATIENT_REPOSITORY_REQUEST:
            return {
                ...state,
                get_care_plan_repo_req: true,
            }
        case carePlansConstants.GET_PATIENT_REPOSITORY_SUCCESS:
            return {
                ...omit(state, 'get_care_plan_repo_req', 'delete_failure'),
                get_care_plan_repo_success: true,
                get_care_plan: action.response
            }
        case carePlansConstants.GET_PATIENT_REPOSITORY_FAILURE:
            return {
                ...omit(state, 'get_care_plan_repo_req', 'get_care_plan_repo_success'),
                get_care_plan_repo_failure: true,
                created_error: action.response
            }

        case carePlansConstants.UPDATE_PATIENT_CARE_PLAN_REQUEST:
            return {
                ...state,
                create_patient_care_plan_req: true
            }
        case carePlansConstants.UPDATE_PATIENT_CARE_PLAN_SUCCESS:
            return {
                ...omit(state, 'create_patient_care_plan_req'),
                patient_care_plan_created: true,
                created_care_plan: action.response
            }//patient_care_plan_created_failure,patient_care_plan_created
        case carePlansConstants.UPDATE_PATIENT_CARE_PLAN_FAILURE:
            return {
                ...omit(state, 'create_patient_care_plan_req', 'patient_care_plan_created'),
                patient_care_plan_created_failure: false,
                created_error: action.response
            }

        case carePlansConstants.CARE_PLAN_REPOSITORY_CLEAR:
            return {
                ...omit(state, [action.payload])
            };

        case carePlansConstants.GET_ASSESSMENT_REQUEST:
            return {
                ...state,
                assessment_request: true
            };

        case carePlansConstants.GET_ASSESSMENT_SUCCESS:
            return {
                ...omit(state, 'assessment_request'),
                assessments: action.response
            };

        case carePlansConstants.GET_ASSESSMENT_FAILURE:
            return {
                ...omit(state, 'assessment_request'),
            }

        case carePlansConstants.GET_DIAGNOSIS_REQUEST:
            return {
                ...state,
                diagnosis_request: true
            };

        case carePlansConstants.GET_DIAGNOSIS_SUCCESS:
            return {
                ...omit(state, 'diagnosis_request'),
                diagnosis: action.response
            };

        case carePlansConstants.GET_DIAGNOSIS_FAILURE:
            return {
                ...omit(state, 'diagnosis_request'),
            }

            case carePlansConstants.CREATE_GOAL_REQUEST:
                return {
                    ...state,
                    create_goal_request: true
                };
    
            case carePlansConstants.CREATE_GOAL_SUCCESS:
                return {
                    ...omit(state, 'create_goal_request'),
                    created_goal: action.response
                };
    
            case carePlansConstants.CREATE_GOAL_FAILURE:
                return {
                    ...omit(state, 'create_goal_request'),
                    create_goal_error: action.response
                }

            case carePlansConstants.UPDATE_GOAL_REQUEST:
                return {
                    ...state,
                    goal_update_request: true
                };
    
            case carePlansConstants.UPDATE_GOAL_SUCCESS:
                return {
                    ...omit(state, 'goal_update_request'),
                    updated_goal: action.response
                };
    
            case carePlansConstants.UPDATE_GOAL_FAILURE:
                return {
                    ...omit(state, 'goal_update_request'),
                    create_goal_error: action.response,
                }
        
    

        default:
            return state;
    }
}
