export const attachmentConstants = {

    CREATE_REQUEST: 'ATTACHEMENT_CREATE_REQUEST',
    CREATE_SUCCESS: 'ATTACHEMENT_CREATE_SUCCESS',
    CREATE_FAILURE: 'ATTACHEMENT_CREATE_FAILURE',

    DOWNLOAD_REQUEST: 'ATTACHEMENT_DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'ATTACHEMENT_DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE: 'ATTACHEMENT_DOWNLOAD_FAILURE',

    CLEAR_PROPS: 'ATTACHMENT_CLEAR_PROPS'
}
