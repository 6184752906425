import { authConstants } from 'js/constants';
import { omit } from 'lodash';

export function auth(state = {}, action) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                login_request: true
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                ...omit(state, 'login_request'),
                loggedIn: true,
                login_response : action.response
            };
        case authConstants.LOGIN_FAILURE:
            return {
                ...omit(state, 'login_request'),
                auth_error: action.response
            };

        case authConstants.LOGIN_DUO_REQUEST:
            return {
                login_duo_request: true
            };
        case authConstants.LOGIN_DUO_SUCCESS:
            return {
                ...omit(state, 'login_duo_request'),
                loggedIn: true,
                login_duo_response : action.response
            };
        case authConstants.LOGIN_DUO_FAILURE:
            return {
                ...omit(state, 'login_duo_request'),
                duo__error: action.response
            };

        case authConstants.LOGIN_OTP_REQUEST:
            return {
                ...omit(state, 'get_all_care_persons_request'),
                login_otp_request: true

            };
        case authConstants.LOGIN_OTP_SUCCESS:
            return {
                ...omit(state, 'login_otp_request'),
                loggedIn: true,
                login_otp_response : action.response
            };
        case authConstants.LOGIN_OTP_FAILURE:
            return {
                ...omit(state, 'login_otp_request'),
                otp_error: action.response
            };

        case authConstants.SIGNUP_STEP_ONE_REQUEST:
            return {
                ...state,
                signup_one_request: true
            };
        case authConstants.SIGNUP_STEP_ONE_SUCCESS:
            return {
                ...omit(state, 'signup_one_request'),
                loggedIn: true
            };
        case authConstants.SIGNUP_STEP_ONE_FAILURE:
            return {
                ...omit(state, 'signup_one_request'),
                auth_error: action.response
            };

        case authConstants.SIGNUP_STEP_TWO_REQUEST:
            return {
                ...state,
                signup_two_request: true
            };
        case authConstants.SIGNUP_STEP_TWO_SUCCESS:
            return {
                ...omit(state, 'signup_two_request'),
            };

        case authConstants.SIGNUP_STEP_TWO_FAILURE:
            return {
                ...omit(state, 'signup_two_request'),
                auth_error: action.response
            };

        case authConstants.SIGNUP_STEP_THREE_REQUEST:
            return {
                ...state,
                signup_three_request: true
            };
        case authConstants.SIGNUP_STEP_THREE_SUCCESS:
            return {
                ...omit(state, 'signup_three_request'),
            };

        case authConstants.SIGNUP_STEP_THREE_FAILURE:
            return {
                ...omit(state, 'signup_three_request'),
                auth_error: action.response
            };

        case authConstants.LOGOUT_SUCCESS:
            return {};

        case authConstants.CLEAR_AUTH_ERRORS:
            return {
                ...omit(state, [action.payload])
            };


        case authConstants.RESET_LINK_REQUEST:
            return {
                reset_link_request: true
            };
        case authConstants.RESET_LINK_SUCCESS:
            return {
                ...omit(state, 'reset_link_request'),
                success_of_reset_link: true
            };
        case authConstants.RESET_LINK_FAILURE:
            return {
                ...omit(state, 'reset_link_request'),
                auth_error: action.response
            };

        case authConstants.RESET_PASSWORD_REQUEST:
            return {
                reset_password_request: true
            };
        case authConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...omit(state, 'reset_password_request'),
                reset_password: action.response
            };
        case authConstants.RESET_PASSWORD_FAILURE:
            return {
                ...omit(state, 'reset_password_request'),
                auth_error: action.response
            };
        case authConstants.USER_PROFILE_SUCCESS:
            return {
                ...omit(state),
                user: action.response
            }
        case authConstants.SET_AUTH_USER_ROUTE:
            return {
                ...omit(state),
                route: action.route
            }

        case authConstants.IMPORT_USER_REQUEST:
            return {
                ...state,
                import_user_data_request: true
                };
        case authConstants.IMPORT_USER_SUCCESS:
            return{
                ...omit(state, 'import_user_data_request'),
                import_user: action.response
            };

        case authConstants.IMPORT_USER_FAILURE:
            return {
                ...omit(state, 'import_user_data_request'),
                auth_error: action.response
            };

        case authConstants.PROFILE_UPDATE_REQUEST:
            return {
                ...state,
                profile_update_request: true
            }
        case authConstants.PROFILE_UPDATE_SUCCESS:
            return {
                ...omit(state, 'profile_update_request',
                    'profile_update_failure',
                    'profile_updated_failure'),
                profile_update_success: true,
                profile_updated_success: action.response
            }
        case authConstants.PROFILE_UPDATE_FAILURE:
            return {
                ...omit(state, 'profile_update_request', 'profile_update_success'),
                profile_update_failure: true,
                profile_updated_failure: action.response
            }
        case authConstants.SEND_VERIFY_REQUEST:
            return {
                ...omit(state, 'sending_phone_verify_success', 'sending_phone_verify_failure'),
                sending_phone_verify_request: true
            }
        case authConstants.SEND_VERIFY_SUCCESS:
            return {
                ...omit(state, 'sending_phone_verify_request', 'sending_phone_verify_failure'),
                sending_phone_verify_success: action.response
            }
        case authConstants.SEND_VERIFY_FAILURE:
            return {
                ...omit(state, 'sending_phone_verify_request', 'sending_phone_verify_success'),
                sending_phone_verify_failure: action.response
            }
        case authConstants.PHONE_VERIFY_REQUEST:
            return {
                ...omit(state, 'phone_verify_failure', 'profile_update_success'),
                phone_verify_request: true
            }
        case authConstants.PHONE_VERIFY_SUCCESS:
            return {
                ...omit(state, 'phone_verify_request', 'phone_verify_failure'),
                profile_update_success: action.response
            }
        case authConstants.PHONE_VERIFY_FAILURE:
            return {
                ...omit(state, 'phone_verify_request', 'profile_update_success'),
                phone_verify_failure: action.response
            }

        case authConstants.CHANGE_ACCOUNT_REQUEST:
            return {
                ...state,
                change_account_request: true
            }

        case authConstants.CHANGE_ACCOUNT_SUCCESS:
            return {
                ...omit(state, 'change_account_request'),
                change_account_success: action.response
            };
        case authConstants.CHANGE_ACCOUNT_FAILURE:
            return{
                ...omit(state, 'change_account_request'),
                change_account_error: action.response
            };

        case authConstants.GET_ACCOUNTS_REQUEST:
            return {
                ...state,
                get_accounts_request: true
            }

        case authConstants.GET_ACCOUNTS_SUCCESS:
            return {
                ...omit(state, 'get_accounts_request'),
                accounts: action.response
            };
        case authConstants.GET_ACCOUNTS_FAILURE:
            return{
                ...omit(state, 'get_accounts_request'),
                change_account_error: action.response
            };

        case authConstants.GET_PATIENT_BY_EMAIL_REQUEST:
            return {
                ...omit(state, 'patient_email_error', 'patient_email', 'auth_error'),
                get_patient_by_mail_request: true
            }

        case authConstants.GET_PATIENT_BY_EMAIL_SUCCESS:
            return {
                ...omit(state, 'get_patient_by_mail_request'),
                patient_email: action.response
            };
        case authConstants.GET_PATIENT_BY_EMAIL_FAILURE:
            return{
                ...omit(state, 'get_patient_by_mail_request'),
                patient_email_error: action.response
            };

        default:
            return state;
    }
}
