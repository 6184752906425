import { attachmentConstants } from 'js/constants';
import { omit } from 'lodash';

const initialState = {};

export function attachment(state = initialState, action) {
    switch (action.type) {

        case attachmentConstants.CREATE_REQUEST:
            return {
                ...state,
                create_request: true
            }

        case attachmentConstants.CREATE_SUCCESS:
            return {
                ...omit(state, 'create_request'),
                file: action.response
            }

        case attachmentConstants.CREATE_FAILURE:
            return {
                ...omit(state, 'create_request'),
                created_error: action.error
            };


        case attachmentConstants.DOWNLOAD_REQUEST:
            return {
                ...state,
                download_request: true
            }

        case attachmentConstants.DOWNLOAD_SUCCESS:
            return {
                ...omit(state, 'download_request'),
                file: action.response
            }

        case attachmentConstants.DOWNLOAD_FAILURE:
            return {
                ...omit(state, 'download_request'),
                download_error: action.error
            };

        case attachmentConstants.CLEAR_PROPS:
            return {
                ...omit(state, [action.payload])
            }

        default:
            return state;
    }
}
