export const authConstants = {
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

    LOGIN_DUO_REQUEST : 'LOGIN_DUO_REQUEST',
    LOGIN_DUO_SUCCESS : 'LOGIN_DUO_SUCCESS',
    LOGIN_DUO_FAILURE : 'LOGIN_DUO_FAILURE',

    SIGNUP_REQUEST: 'AUTH_SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'AUTH_SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'AUTH_SIGNUP_FAILURE',

    SIGNUP_STEP_ONE_REQUEST: 'AUTH_SIGNUP_STEP_ONE_REQUEST',
    SIGNUP_STEP_ONE_SUCCESS: 'AUTH_SIGNUP_STEP_ONE_SUCCESS',
    SIGNUP_STEP_ONE_FAILURE: 'AUTH_SIGNUP_STEP_ONE_FAILURE',

    SIGNUP_STEP_TWO_REQUEST: 'AUTH_SIGNUP_STEP_TWO_REQUEST',
    SIGNUP_STEP_TWO_SUCCESS: 'AUTH_SIGNUP_STEP_TWO_SUCCESS',
    SIGNUP_STEP_TWO_FAILURE: 'AUTH_SIGNUP_STEP_TWO_FAILURE',

    SIGNUP_STEP_THREE_REQUEST: 'AUTH_SIGNUP_STEP_THREE_REQUEST',
    SIGNUP_STEP_THREE_SUCCESS: 'AUTH_SIGNUP_STEP_THREE_SUCCESS',
    SIGNUP_STEP_THREE_FAILURE: 'AUTH_SIGNUP_STEP_THREE_FAILURE',

    RESET_LINK_REQUEST: 'AUTH_RESET_LINK_REQUEST',
    RESET_LINK_SUCCESS: 'AUTH_RESET_LINK_SUCCESS',
    RESET_LINK_FAILURE: 'AUTH_RESET_LINK_FAILURE',

    RESET_PASSWORD_REQUEST: 'AUTH_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'AUTH_RESET_PASSWORD_FAILURE',

    LOGOUT: 'AUTH_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
    LOGOUT_FAILER: 'AUTH_LOGOUT_FAILER',

    TOKEN_UNAUTHORISED: 'TOKEN_UNAUTHORISED_REQUEST',

    CLEAR_AUTH_ERRORS: 'CLEAR_AUTH_ERRORS',

    USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
    USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

    IMPORT_USER_REQUEST : 'IMPORT_USER_DATA_REQUEST',
    IMPORT_USER_SUCCESS : 'IMPORT_USER_DATA_SUCCESS',
    IMPORT_USER_FAILURE : 'IMPORT_USER_DATA_FAILURE',

    CHANGE_ACCOUNT_REQUEST : 'CHANGE_ACCOUNT_USER_REQUEST',
    CHANGE_ACCOUNT_SUCCESS : 'CHANGE_ACCOUNT_USER_SUCCESS',
    CHANGE_ACCOUNT_FAILURE : 'CHANGE_ACCOUNT_USER_FAILURE',

    GET_ACCOUNTS_REQUEST : 'GET_USER_ACCOUNTS_REQUEST',
    GET_ACCOUNTS_SUCCESS : 'GET_USER_ACCOUNTS_SUCCESS',
    GET_ACCOUNTS_FAILURE : 'GET_USER_ACCOUNTS_FAILURE',

    SET_AUTH_USER_ROUTE  : "SET_AUTH_USER_ROUTE",

    PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
    PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
    PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',

    LOGIN_OTP_REQUEST : 'LOGIN_OTP_REQUEST',
    LOGIN_OTP_SUCCESS : 'LOGIN_OTP_SUCCESS',
    LOGIN_OTP_FAILURE : 'LOGIN_OTP_FAILURE',

    SEND_VERIFY_REQUEST : 'SEND_VERIFY_REQUEST',
    SEND_VERIFY_SUCCESS : 'SEND_VERIFY_SUCCESS',
    SEND_VERIFY_FAILURE : 'SEND_VERIFY_FAILURE',

    PHONE_VERIFY_REQUEST : 'PHONE_VERIFY_REQUEST',
    PHONE_VERIFY_SUCCESS : 'PHONE_VERIFY_SUCCESS',
    PHONE_VERIFY_FAILURE : 'PHONE_VERIFY_FAILURE',


    GET_PATIENT_BY_EMAIL_REQUEST : 'AUTH_GET_PATIENT_BY_EMAIL_REQUEST',
    GET_PATIENT_BY_EMAIL_SUCCESS : 'AUTH_GET_PATIENT_BY_EMAIL_SUCCESS',
    GET_PATIENT_BY_EMAIL_FAILURE : 'AUTH_GET_PATIENT_BY_EMAIL_FAILURE',
};
