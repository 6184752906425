export const careTeamConstants = {

    CLEAR_CARE_TEAM_PROPS: 'CLEAR_CARE_TEAM_PROPS',

    GET_ALL_CARE_PERSONS_REQUEST: 'GET_ALL_CARE_PERSONS_REQUEST',
    GET_ALL_CARE_PERSONS_SUCCESS: 'GET_ALL_CARE_PERSONS_SUCCESS',
    GET_ALL_CARE_PERSONS_FAILURE: 'GET_ALL_CARE_PERSONS_FAILURE',

    ASSIGN_CARE_PERSON_REQUEST: 'ASSIGN_CARE_PERSON_REQUEST',
    ASSIGN_CARE_PERSON_SUCCESS: 'ASSIGN_CARE_PERSON_SUCCESS',
    ASSIGN_CARE_PERSON_FAILURE: 'ASSIGN_CARE_PERSON_FAILURE',

    REVOKE_CARE_PERSON_REQUEST: 'REVOKE_CARE_PERSON_REQUEST',
    REVOKE_CARE_PERSON_SUCCESS: 'REVOKE_CARE_PERSON_SUCCESS',
    REVOKE_CARE_PERSON_FAILURE: 'REVOKE_CARE_PERSON_FAILURE',

    GET_CARE_PERSON_REQUEST: 'GET_CARE_PERSON_REQUEST',
    GET_CARE_PERSON_SUCCESS: 'GET_CARE_PERSON_SUCCESS',
    GET_CARE_PERSON_FAILURE: 'GET_CARE_PERSON_FAILURE',

    CREATE_CARE_PERSON_REQUEST: 'CREATE_CARE_PERSON_REQUEST',
    CREATE_CARE_PERSON_SUCCESS: 'CREATE_CARE_PERSON_SUCCESS',
    CREATE_CARE_PERSON_FAILURE: 'CREATE_CARE_PERSON_FAILURE',

    UPDATE_CARE_PERSON_REQUEST: 'UPDATE_CARE_PERSON_REQUEST',
    UPDATE_CARE_PERSON_SUCCESS: 'UPDATE_CARE_PERSON_SUCCESS',
    UPDATE_CARE_PERSON_FAILURE: 'UPDATE_CARE_PERSON_FAILURE',

    REMOVE_CARE_PERSON_REQUEST: 'REMOVE_CARE_PERSON_REQUEST',
    REMOVE_CARE_PERSON_SUCCESS: 'REMOVE_CARE_PERSON_SUCCESS',
    REMOVE_CARE_PERSON_FAILURE: 'REMOVE_CARE_PERSON_FAILURE',

    GET_ASSIGNED_MEMBERS_REQUEST: 'GET_ASSIGNED_MEMBERS_REQUEST',
    GET_ASSIGNED_MEMBERS_SUCCESS: 'GET_ASSIGNED_MEMBERS_SUCCESS',
    GET_ASSIGNED_MEMBERS_FAILURE: 'GET_ASSIGNED_MEMBERS_FAILURE',
};
