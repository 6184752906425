export const carePlansConstants = {


    CARE_PLAN_REPOSITORY_CLEAR: 'CARE_PLAN_REPOSITORY_CLEAR',

    GET_ALL_CARE_PLANS_REPOSITORY_REQUEST: 'GET_ALL_CARE_PLANS_REPOSITORY_REQUEST',
    GET_ALL_CARE_PLANS_REPOSITORY_SUCCESS: 'GET_ALL_CARE_PLANS_REPOSITORY_SUCCESS',
    GET_ALL_CARE_PLANS_REPOSITORY_FAILURE: 'GET_ALL_CARE_PLANS_REPOSITORY_FAILURE',

    DELETE_CARE_PLANS_REPOSITORY_REQUEST: 'DELETE_CARE_PLANS_REPOSITORY_REQUEST',
    DELETE_CARE_PLANS_REPOSITORY_SUCCESS: 'DELETE_CARE_PLANS_REPOSITORY_SUCCESS',
    DELETE_CARE_PLANS_REPOSITORY_FAILURE: 'DELETE_CARE_PLANS_REPOSITORY_FAILURE',

    CREATE_CARE_PLANS_REPOSITORY_REQUEST: 'CREATE_CARE_PLANS_REPOSITORY_REQUEST',
    CREATE_CARE_PLANS_REPOSITORY_SUCCESS: 'CREATE_CARE_PLANS_REPOSITORY_SUCCESS',
    CREATE_CARE_PLANS_REPOSITORY_FAILURE: 'CREATE_CARE_PLANS_REPOSITORY_FAILURE',

    GET_CARE_PLAN_REPOSITORY_REQUEST: 'GET_CARE_PLAN_REPOSITORY_REQUEST',
    GET_CARE_PLAN_REPOSITORY_SUCCESS: 'GET_CARE_PLAN_REPOSITORY_SUCCESS',
    GET_CARE_PLAN_REPOSITORY_FAILURE: 'GET_CARE_PLAN_REPOSITORY_FAILURE',

    UPDATE_CARE_PLANS_REPOSITORY_REQUEST: 'UPDATE_CARE_PLANS_REPOSITORY_REQUEST',
    UPDATE_CARE_PLANS_REPOSITORY_SUCCESS: 'UPDATE_CARE_PLANS_REPOSITORY_SUCCESS',
    UPDATE_CARE_PLANS_REPOSITORY_FAILURE: 'UPDATE_CARE_PLANS_REPOSITORY_FAILURE',

    GET_PATIENT_CARE_PLAN_REQUEST: 'GET_PATIENT_CARE_PLAN_REQUEST',
    GET_PATIENT_CARE_PLAN_SUCCESS: 'GET_PATIENT_CARE_PLAN_SUCCESS',
    GET_PATIENT_CARE_PLAN_FAILURE: 'GET_PATIENT_CARE_PLAN_FAILURE',

    CREATE_PATIENT_CARE_PLAN_REQUEST: 'CREATE_PATIENT_CARE_PLAN_REQUEST',
    CREATE_PATIENT_CARE_PLAN_SUCCESS: 'CREATE_PATIENT_CARE_PLAN_SUCCESS',
    CREATE_PATIENT_CARE_PLAN_FAILURE: 'CREATE_PATIENT_CARE_PLAN_FAILURE',

    GET_PATIENT_REPOSITORY_REQUEST: 'CARE_PLAN_ GET_PATIENT_REPOSITORY_REQUEST',
    GET_PATIENT_REPOSITORY_SUCCESS: 'CARE_PLAN_ GET_PATIENT_REPOSITORY_SUCCESS',
    GET_PATIENT_REPOSITORY_FAILURE: 'CARE_PLAN_ GET_PATIENT_REPOSITORY_FAILURE',

    UPDATE_PATIENT_CARE_PLAN_REQUEST: 'UPDATE_PATIENT_CARE_PLAN_REQUEST',
    UPDATE_PATIENT_CARE_PLAN_SUCCESS: 'UPDATE_PATIENT_CARE_PLAN_SUCCESS',
    UPDATE_PATIENT_CARE_PLAN_FAILURE: 'UPDATE_PATIENT_CARE_PLAN_FAILURE',

    GET_ASSESSMENT_REQUEST: 'CARE_PLAN_GET_ASSESSMENT_REQUEST',
    GET_ASSESSMENT_SUCCESS: 'CARE_PLAN_GET_ASSESSMENT_SUCCESS',
    GET_ASSESSMENT_FAILURE: 'CARE_PLAN_GET_ASSESSMENT_FAILURE',

    GET_DIAGNOSIS_REQUEST: 'CARE_PLAN_GET_DIAGNOSIS_REQUEST',
    GET_DIAGNOSIS_SUCCESS: 'CARE_PLAN_GET_DIAGNOSIS_SUCCESS',
    GET_DIAGNOSIS_FAILURE: 'CARE_PLAN_GET_DIAGNOSIS_FAILURE',

    CREATE_GOAL_REQUEST: 'CREATE_CARE_PLAN_GOAL_REQUEST',
    CREATE_GOAL_SUCCESS: 'CREATE_CARE_PLAN_GOAL_SUCCESS',
    CREATE_GOAL_FAILURE: 'CREATE_CARE_PLAN_GOAL_FAILURE',

    UPDATE_GOAL_REQUEST: 'UPDATE_CARE_PLAN_GOAL_REQUEST',
    UPDATE_GOAL_SUCCESS: 'UPDATE_CARE_PLAN_GOAL_SUCCESS',
    UPDATE_GOAL_FAILURE: 'UPDATE_CARE_PLAN_GOAL_FAILURE',

    UPDATE_CARE_PLAN_STATUS_REPOSITORY_REQUEST: 'UPDATE_CARE_PLAN_STATUS_REPOSITORY_REQUEST',
    UPDATE_CARE_PLAN_STATUS_REPOSITORY_SUCCESS: 'UPDATE_CARE_PLAN_STATUS_REPOSITORY_SUCCESS',
    UPDATE_CARE_PLANS_STATUS_REPOSITORY_FAILURE: 'UPDATE_CARE_PLANS_STATUS_REPOSITORY_FAILURE',

};
