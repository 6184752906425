export const notificationsConstants = {


    CLEAR_PATIENT_NOTIFICATIONS_PROPS: 'CLEAR_PATIENT_NOTIFICATIONS_PROPS',

    GET_ALL_NOTIFICATIONS_REQUEST: 'GET_ALL_NOTIFICATIONS_REQUEST',
    GET_ALL_NOTIFICATIONS_SUCCESS: 'GET_ALL_NOTIFICATIONS_SUCCESS',
    GET_ALL_NOTIFICATIONS_FAILURE: 'GET_ALL_NOTIFICATIONS_FAILURE',

    UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
    UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
    UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',

    REMOVE_NOTIFICATION_REQUEST: 'REMOVE_NOTIFICATION_REUEST',
    REMOVE_NOTIFICATION_SUCCESS: 'REMOVE_NOTIFICATION_SUCCESS',
    REMOVE_NOTIFICATION_FAILURE: 'REMOVE_NOTIFICATION_FAILURE',

    GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
    GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
    GET_NOTIFICATION_FAILURE: 'GET_NOTIFICATION_FAILURE',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',

    MARK_NOTIFICATION_REQUEST: 'MARK_NOTIFICATION_AS_READ_REQUEST',
    MARK_NOTIFICATION_SUCCESS: 'MARK_NOTIFICATION_AS_READ_SUCCESS',
    MARK_NOTIFICATION_FAILURE: 'MARK_NOTIFICATION_AS_READ_FAILURE',

    GET_UNREAD_NOTIFICATION_REQUEST: 'UNREAD_NOTIFICATION_REQUEST',
    GET_UNREAD_NOTIFICATION_SUCCESS: 'UNREAD_NOTIFICATION_SUCCESS',
    GET_UNREAD_NOTIFICATION_FAILURE: 'UNREAD_NOTIFICATION_FAILURE',
};
