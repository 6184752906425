import { medicalRecordsConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';



const initialState = {
    meta_data : {
        'immunizations' : { title : { label : "Immunization Record", value :  "title" },  date : { label : "Creation Date", value: "creation_date"  } },
        'procedures' : { title : { label : "Procedures", value :  "title" },  date : { label : "Creation Date", value: "creation_date"  } },
        'hospital-visits' : { title : { label : "Hospital", value :  "title" },  date : { label : "Visit Date", value: "visit_date"  } },
        'doctor-visits' : { title : { label : "Doctor", value :  "title" },  date : { label : "Visit Date", value: "visit_date"  } },
        'ld-images' : { title : { label : "Title", value :  "title" },  date : { label : "Creation Date", value: "creation_date"  } },
    }
};

export function medicalRecords(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case medicalRecordsConstants.GET_MEDICAL_RECORDS_REQUEST:
            return {
                ...state,
                get_all_request: true
            };
        case medicalRecordsConstants.GET_MEDICAL_RECORDS_SUCCESS:
            return {
                ...omit(state, 'get_all_request'),
                [action.payload]: action.response
            }
        case medicalRecordsConstants.GET_MEDICAL_RECORDS_FAILURE:
            return {
                ...omit(state, 'get_all_request'),
                create_error: action.response
            }

        case medicalRecordsConstants.CREATE_MEDICAL_RECORDS_REQUEST:
            return {
                ...state,
                create_medical_record_request: true
            };
        case medicalRecordsConstants.CREATE_MEDICAL_RECORDS_SUCCESS:
            return {
                ...omit(state, 'create_medical_record_request'),
                created_medical_record: true
            }
        case medicalRecordsConstants.CREATE_MEDICAL_RECORDS_FAILURE:
            return {
                ...omit(state, 'create_medical_record_request'),
                create_error: action.response
            }

        case medicalRecordsConstants.UPDATE_REQUEST:
            return {
                ...omit(state, 'updated_medical_record'),
                update_medical_record_request: true
            };
        case medicalRecordsConstants.UPDATE_SUCCESS:
            return {
                ...omit(state, 'update_medical_record_request'),
                updated_medical_record: true
            }
        case medicalRecordsConstants.UPDATE_FAILURE:
            return {
                ...omit(state, 'update_medical_record_request'),
                update_error: action.response
            }

        case medicalRecordsConstants.CREATE_FILE_ATTACHEMENT_REQUEST:
            return {
                ...omit(state, [action.payload.success, action.payload.error ]),
                [action.payload.request]: true,
            };
        case medicalRecordsConstants.CREATE_FILE_ATTACHEMENT_SUCCESS:
            return {
                ...omit(state, [action.payload.request, action.payload.error ]),
                [action.payload.success]: action.response,
                upload_file_success : true,
                upload_medical_record_id : action.payload.record_id,
                record_type: action.payload.record_type
            };
        case medicalRecordsConstants.CREATE_FILE_ATTACHEMENT_FAILURE:
            return {
                ...omit(state, [action.payload.request, action.payload.success ]),
                [action.payload.error]: action.response
            };
        case medicalRecordsConstants.CLEAR_MEDICAL_RECORDS_PROPS:
            return {
                ...omit(state, [action.payload])
            }
        default:
            return state;
    }
}
