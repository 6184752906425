import { loggingConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';



const initialState = {};

export function logging(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case loggingConstants.GET_LOGS_REQUEST:
            return {
                ...omit(state, ['get_logs_success', 'get_logs_failure']),
                get_logs_request: true,
            };
        case loggingConstants.GET_LOGS_SUCCESS:
            return {
                ...omit(state, ['get_logs_request', 'get_logs_failure']),
                logs: action.response,
                get_logs_success : true,
            };
        case loggingConstants.GET_LOGS_FAILURE:
            return {
                ...omit(state, ['get_logs_request', 'get_logs_success']),
                get_logs_failure : true,
            };
        case loggingConstants.GET_LOG_TYPE_REQUEST:
            return {
                ...omit(state, ['get_log_type_success', 'get_log_type_failure']),
                get_log_type_request: true,
            };
        case loggingConstants.GET_LOG_TYPE_SUCCESS:
            return {
                ...omit(state, ['get_log_type_request', 'get_log_type_failure']),
                log_types: action.response,
                get_log_type_success : true,
            };
        case loggingConstants.GET_LOG_TYPE_FAILURE:
            return {
                ...omit(state, ['get_log_type_request', 'get_log_type_success']),
                get_log_type_failure : true,
            };
        
        case loggingConstants.DOWNLOAD_REQUEST:
            return {
                ...omit(state, ['downlod_success', 'downlod_failure']),
                downlod_request: true,
            };
        case loggingConstants.DOWNLOAD_SUCCESS:
            return {
                ...omit(state, ['downlod_request', 'downlod_failure']),
                downlod_success : true,
            };
        case loggingConstants.DOWNLOAD_FAILURE:
            return {
                ...omit(state, ['downlod_request', 'downlod_success']),
                downlod_failure : true,
            };
        

        default:
            return state;
    }
}
