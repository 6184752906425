import { dashboardConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';



const initialState = {};

export function dashboard(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return {};

        case dashboardConstants.GET_ALL_DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                get_all_request: true
            };
        case dashboardConstants.GET_ALL_DASHBOARD_DATA_SUCCESS:
            return {
                ...omit(state, 'get_all_request'),
                dashboard_data: action.response
            }
        case dashboardConstants.GET_ALL_DASHBOARD_DATA_FAILURE:
            return {
                ...omit(state, 'get_all_request'),
                create_error: action.response
            }

        case dashboardConstants.GET_ALL_CARE_ASSISTANTS_REQUEST:
            return {
                ...state,
                get_all_assistants_request: true
            };
        case dashboardConstants.GET_ALL_CARE_ASSISTANTS_SUCCESS:
            return {
                ...omit(state, 'get_all_assistants_request', 'get_all_assistants_failure'),
                get_all_assistants_success: true,
                assistants_list: action.response
            }
        case dashboardConstants.GET_ALL_CARE_ASSISTANTS_FAILURE:
            return {
                ...omit(state, 'get_all_assistants_request', 'get_all_assistants_success'),
                get_all_assistants_failure: false,
                create_error: action.response
            }

        case dashboardConstants.GET_CARE_PERSON_TASK_REQUEST:
            return {
                ...state,
                get_all_care_person_task_req: true
            };
        case dashboardConstants.GET_CARE_PERSON_TASK_SUCCESS:
            return {
                ...omit(state, 'get_all_care_person_task_req', 'get_all_care_person_task_failure'),
                get_all_care_person_task_success: true,
                task_list: action.response
            }
        case dashboardConstants.GET_CARE_PERSON_TASK_FAILURE:
            return {
                ...omit(state, 'get_all_care_person_task_req', 'get_all_care_person_task_success'),
                get_all_care_person_task_failure: false,
                create_error: action.response
            }

        case dashboardConstants.GET_RECENT_CARE_PERSON_CALL_REQUEST:
            return {
                ...state,
                get_care_person_call_req: true
            };
        case dashboardConstants.GET_RECENT_CARE_PERSON_CALL_SUCCESS:
            return {
                ...omit(state, 'get_care_person_call_req', 'get_care_person_call_failure'),
                get_care_person_call_success: true,
                [action.payload]: action.response
            }
        case dashboardConstants.GET_RECENT_CARE_PERSON_CALL_FAILURE:
            return {
                ...omit(state, 'get_care_person_call_req', 'get_care_person_call_success'),
                get_care_person_call_failure: false,
                create_error: action.response
            }

        case dashboardConstants.GET_RECENT_CARE_PERSON_ACTIVITIES_REQUEST:
            return {
                ...state,
                get_care_person_activities_req: true
            };
        case dashboardConstants.GET_RECENT_CARE_PERSON_ACTIVITIES_SUCCESS:
            return {
                ...omit(state, 'get_care_person_activities_req', 'get_care_person_activities_failure'),
                get_care_person_activities_success: true,
                [action.payload]: action.response
            }
        case dashboardConstants.GET_RECENT_CARE_PERSON_ACTIVITIES_FAILURE:
            return {
                ...omit(state, 'get_care_person_activities_req', 'get_care_person_activities_success'),
                get_care_person_activities_failure: false,
                create_error: action.response
            }

        case dashboardConstants.GET_RECENT_CARE_PERSON_MESSAGES_REQUEST:
            return {
                ...state,
                get_care_person_messages_req: true
            };
        case dashboardConstants.GET_RECENT_CARE_PERSON_MESSAGES_SUCCESS:
            return {
                ...omit(state, 'get_care_person_messages_req', 'get_care_person_messages_failure'),
                get_care_person_messages_success: true,
                [action.payload]: action.response
            }
        case dashboardConstants.GET_RECENT_CARE_PERSON_MESSAGES_FAILURE:
            return {
                ...omit(state, 'get_care_person_messages_req', 'get_care_person_messages_success'),
                get_care_person_messages_failure: false,
                create_error: action.response
            }


        case dashboardConstants.GET_ALL_RECENT_REQUEST:
            return {
                ...state,
                get_all_recent: true
            };
        case dashboardConstants.GET_ALL_RECENT_SUCCESS:
            return {
                ...omit(state, 'get_all_recent'),
                recent: action.response
            }
        case dashboardConstants.GET_ALL_RECENT_FAILURE:
            return {
                ...omit(state, 'get_all_recent'),
                create_error: action.response
            }


        case dashboardConstants.CLEAR_DASHBOARD_PROPS:
            return {
                ...omit(state, [action.payload])
            }

        default:
            return state;
    }
}
