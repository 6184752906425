export const eventConstants = {


    CLEAR_PROPS: 'EVENT_CLEAR_PROPS',

    GET_ALL_REQUEST: 'EVENT_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'EVENT_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'EVENT_GET_ALL_FAILURE',

    UPDATE_REQUEST: 'EVENT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'EVENT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'EVENT_UPDATE_FAILURE',

    CANCEL_REQUEST: 'EVENT_CANCEL_REQUEST',
    CANCEL_SUCCESS: 'EVENT_CANCEL_SUCCESS',
    CANCEL_FAILURE: 'EVENT_CANCEL_FAILURE',

    GET_REQUEST: 'EVENT_GET_REQUEST',
    GET_SUCCESS: 'EVENT_GET_SUCCESS',
    GET_FAILURE: 'EVENT_GET_FAILURE',

    ADD_REQUEST: 'EVENT_ADD_REQUEST',
    ADD_SUCCESS: 'EVENT_ADD_SUCCESS',
    ADD_FAILURE: 'EVENT_ADD_FAILURE',

    REMOVE_REQUEST: 'EVENT_REMOVE_REQUEST',
    REMOVE_SUCCESS: 'EVENT_REMOVE_SUCCESS',
    REMOVE_FAILURE: 'EVENT_REMOVE_FAILURE',
};
