export const taskConstants = {
    GET_ALL_REQUEST: 'TASK_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'TASK_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'TASK_GET_ALL_FAILURE',

    TOOGLE_COMPLETE_REQUEST: 'TASK_TOOGLE_COMPLETE_REQUEST',
    TOOGLE_COMPLETE_SUCCESS: 'TASK_TOOGLE_COMPLETE_SUCCESS',
    TOOGLE_COMPLETE_FAILURE: 'TASK_TOOGLE_COMPLETE_FAILURE',

    GET_CALANDAR_REQUEST: 'TASK_GET_CALANDAR_REQUEST',
    GET_CALANDAR_SUCCESS: 'TASK_GET_CALANDAR_SUCCESS',
    GET_CALANDAR_FAILURE: 'TASK_GET_CALANDAR_FAILURE',

    CREATE_REQUEST: 'TASK_CREATE_REQUEST',
    CREATE_SUCCESS: 'TASK_CREATE_SUCCESS',
    CREATE_FAILURE: 'TASK_CREATE_FAILURE',

    UPDATE_REQUEST: 'TASK_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TASK_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TASK_UPDATE_FAILURE',

    REMOVE_REQUEST: 'TASK_REMOVE_REQUEST',
    REMOVE_SUCCESS: 'TASK_REMOVE_SUCCESS',
    REMOVE_FAILURE: 'TASK_REMOVE_FAILURE',

    ARCHIVE_REQUEST: 'ARCHIVE_TASK_REQUEST',
    ARCHIVE_SUCCESS: 'ARCHIVE_TASK_SUCCESS',
    ARCHIVE_FAILURE: 'ARCHIVE_TASK_FAILURE',


    DOWNLOAD_ATTACHMENT_REQUEST: 'TASK_DOWNLOAD_ATTACHMENT_REQUEST',
    DOWNLOAD_ATTACHMENT_SUCCESS: 'TASK_DOWNLOAD_ATTACHMENT_SUCCESS',
    DOWNLOAD_ATTACHMENT_FAILURE: 'TASK_DOWNLOAD_ATTACHMENT_FAILURE',

    CLEAR_PROPS: 'TASK_CLEAR_PROPS',
};
