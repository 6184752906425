import { callCenterConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';

const initialState = {};

export function callCenter(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;

        case callCenterConstants.GET_ALL_REQUEST:
            return {
                ...omit(state, ['get_all_success', 'get_all_failure']),
                get_all_request: true,
            }
        case callCenterConstants.GET_ALL_SUCCESS:
            return {
                ...omit(state, ['get_all_request', 'get_all_failure']),
                get_all_success: true,
                list: action.response
            }
        case callCenterConstants.GET_ALL_FAILURE:
            return {
                ...omit(state, ['get_all_request', 'get_all_success']),
                get_all_failure: true,
                get_all_error: action.response
            }

        case callCenterConstants.GET_REQUEST:
            return {
                ...omit(state, ['get_success', 'get_failure']),
                get_request: true,
            }
        case callCenterConstants.GET_SUCCESS:
            return {
                ...omit(state, ['get_request', 'get_failure']),
                get_success: true,
                selected: action.response
            }
        case callCenterConstants.GET_FAILURE:
            return {
                ...omit(state, ['get_request', 'get_success']),
                get_failure: true,
                get_error: action.response
        }

        case callCenterConstants.CREATE_PROXY_REQUEST:
            return {
                ...omit(state, 'create_request', 'create_failure'),
                create_request: true
            }
        case callCenterConstants.CREATE_PROXY_SUCCESS:
            return {
                ...omit(state, 'create_request', 'create_failure'),
                created_success: true,
                proxy_created: action.response
            }
        case callCenterConstants.CREATE_PROXY_FAILURE:
            return {
                ...omit(state, 'create_request', 'created_success'),
                create_failure: true,
                created_error: action.response
            }

        case callCenterConstants.CREATE_CARE_TEAM_PROXY_REQUEST:
            return {
                ...omit(state, 'care_team_proxy_created', 'create_care_team_request', 'create_care_team_failure'),
                create_care_team_request: true,
            }
        case callCenterConstants.CREATE_CARE_TEAM_PROXY_SUCCESS:
            return {
                ...omit(state, 'create_care_team_request', 'create_care_team_failure'),
                created_care_team_success: true,
                care_team_proxy_created: action.response
            }
        case callCenterConstants.CREATE_CARE_TEAM_PROXY_FAILURE:
            return {
                ...omit(state, 'create_care_team_request', 'created_care_team_success'),
                create_care_team_failure: true,
                created_care_team_error: action.response
            }

        case callCenterConstants.GET_ALL_NOTES_REQUEST:
            return {
                ...omit(state, ['get_all_notes_success', 'get_all_notes_failure']),
                get_all_notes_request: true,
            }
        case callCenterConstants.GET_ALL_NOTE_SUCCESS:
            return {
                ...omit(state, ['get_all_notes_request', 'get_all_notes_failure']),
                get_all_notes_success: true,
                notes: action.response
            }
        case callCenterConstants.GET_ALL_NOTES_FAILURE:
            return {
                ...omit(state, ['get_all_notes_request', 'get_all_notes_success']),
                get_all_notes_failure: true,
                get_all_notes_error: action.response
            }

        case callCenterConstants.CREATE_NOTE_REQUEST:
            return {
                ...omit(state, 'create_note_request', 'create_note_failure', 'created_note_error'),
                create_note_request: true
            }
        case callCenterConstants.CREATE_NOTE_SUCCESS:
            return {
                ...omit(state, 'create_note_request', 'create_note_failure', 'created_note_error'),
                created_note_success: true,
                created_note: action.response
            }
        case callCenterConstants.CREATE_NOTE_FAILURE:
            return {
                ...omit(state, 'create_note_request', 'created_note_success'),
                create_note_failure: true,
                created_note_error: action.response
            }

        case callCenterConstants.GET_ALL_REQUEST_REQUEST:
            return {
                ...state,
                get_all_call_request: true,
            }
        case callCenterConstants.GET_ALL_REQUEST_SUCCESS:
            return {
                ...omit(state, ['get_all_call_request', 'get_all_request_error']),
                [action.payload]: action.response
            }
        case callCenterConstants.GET_ALL_REQUEST_FAILURE:
            return {
                ...omit(state, ['get_all_request', 'get_all_success']),
                get_all_request_error: action.response
            }

        case callCenterConstants.GET_CALL_HISTORY_REQUEST:
            return {
                ...state,
                get_call_history_request: true,
            }
        case callCenterConstants.GET_CALL_HISTORY_SUCCESS:
            return {
                ...omit(state, ['get_call_history_request', 'get_history_error']),
                call_history: action.response
            }
        case callCenterConstants.GET_CALL_HISTORY_FAILURE:
            return {
                ...omit(state, ['get_call_history_request']),
                get_history_error: action.response
            }

        case callCenterConstants.CANCEL_CALL_REQUEST:
            return {
                ...state,
                cancel_call_request: true,
            }
        case callCenterConstants.CANCEL_CALL_SUCCESS:
            return {
                ...omit(state, ['cancel_call_request', 'cancel_call_error']),
            }
        case callCenterConstants.CANCEL_CALL_FAILURE:
            return {
                ...omit(state, ['cancel_call_request']),
                cancel_call_error: action.response
            }

        case callCenterConstants.MARK_CALL_AS_DONE_REQUEST:
            return {
                ...state,
                mark_call_as_done_request: true,
            }
        case callCenterConstants.MARK_CALL_AS_DONE_SUCCESS:
            return {
                ...omit(state, ['mark_call_as_done_request', 'mark_call_as_done_error']),
            }
        case callCenterConstants.MARK_CALL_AS_DONE_FAILURE:
            return {
                ...omit(state, ['cancel_call_request']),
                mark_call_as_done_error: action.response
            }

        case callCenterConstants.GET_CALL_REQUESTS_REQUEST:
            return {
                ...state,
                get_call_requests: true,
            }
        case callCenterConstants.GET_CALL_REQUESTS_SUCCESS:
            return {
                ...omit(state, 'get_call_requests'),
                call_requests: action.response
            }
        case callCenterConstants.GET_CALL_REQUESTS_FAILURE:
            return {
                ...omit(state, 'get_call_requests'),
                mark_call_as_done_error: action.response
            }

        case callCenterConstants.CLEAR:
            return {
                ...omit(state, [action.payload])
            };

        default:
            return state;
    }
}
