import { lazy } from 'react';
const AppPatient = lazy(() => import("js/components/patient/AppPatient"));
const AppNurse = lazy(() => import("js/components/nurse/AppNurse"));
const AppAdmin = lazy(() => import("js/components/admin/AppAdmin"));

export const IndexRoutes = [
	{ path: "/member", component: AppPatient },
	{ path: "/nurse", component: AppNurse },
	{ path: "/modrnadmin", component: AppAdmin },
	{ path: '/', component: AppPatient},
];
