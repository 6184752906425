import { notificationsConstants, authConstants } from 'js/constants';
import { omit } from 'lodash';



const initialState = {};

export function notifications(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_SUCCESS:
            return initialState;
            
        case notificationsConstants.CLEAR_PATIENT_NOTIFICATIONS_PROPS:
            return {
                ...omit(state, [action.payload])
            }

        case notificationsConstants.GET_ALL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                get_all_notifications_request: true
            };
        case notificationsConstants.GET_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...omit(state, 'get_all_notifications_request'),
                notifications: action.response
            }
        case notificationsConstants.GET_ALL_NOTIFICATIONS_FAILURE:
            return {
                ...omit(state, 'get_all_notifications_request'),
            }

        case notificationsConstants.GET_NOTIFICATION_REQUEST:
            return {
                ...state,
                get_notiication_request: true
            };
        case notificationsConstants.GET_NOTIFICATION_SUCCESS:
            return {
                ...omit(state, 'get_notiication_request'),
                selected_notification: action.response
            }
        case notificationsConstants.GET_NOTIFICATION_FAILURE:
            return {
                ...omit(state, 'get_notiication_request'),
                create_error: action.response
            }

        case notificationsConstants.UPDATE_NOTIFICATION_REQUEST:
            return {
                ...state,
                update_notiication_request: true,
                loading: true
            };
        case notificationsConstants.UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...omit(state, 'update_notiication_request', 'loading'),
                updated_notification: action.response
            }
        case notificationsConstants.UPDATE_NOTIFICATION_FAILURE:
            return {
                ...omit(state, 'update_notiication_request', 'loading'),
                create_error: action.response
            }

        case notificationsConstants.ADD_NOTIFICATION_REQUEST:
            return {
                ...state,
                create_notiication_request: true,
                loading: true
            };
        case notificationsConstants.ADD_NOTIFICATION_SUCCESS:
            return {
                ...omit(state, 'create_notiication_request', 'loading'),
                created_notification: action.response
            }
        case notificationsConstants.ADD_NOTIFICATION_FAILURE:
            return {
                ...omit(state, 'create_notiication_request', 'loading'),
                create_error: action.response
            }

        case notificationsConstants.REMOVE_NOTIFICATION_REQUEST:
            return {
                ...state,
                remove_notiication_request: true
            };
        case notificationsConstants.REMOVE_NOTIFICATION_SUCCESS:
            return {
                ...omit(state, 'remove_notiication_request'),
                removed_notification: action.response
            }
        case notificationsConstants.REMOVE_NOTIFICATION_FAILURE:
            return {
                ...omit(state, 'remove_notiication_request'),
                create_error: action.response
            }

        case notificationsConstants.MARK_NOTIFICATION_REQUEST:
            return {
                ...state,
                mark_notiication_request: true
            };
        case notificationsConstants.MARK_NOTIFICATION_SUCCESS:
            return {
                ...omit(state, 'mark_notiication_request', 'mark_notification_error'),
            }
        case notificationsConstants.MARK_NOTIFICATION_FAILURE:
            return {
                ...omit(state, 'mark_notiication_request'),
                mark_notification_error: action.response
            }

        case notificationsConstants.GET_UNREAD_NOTIFICATION_REQUEST:
            return {
                ...state,
                unread_notiication_request: true
            };
        case notificationsConstants.GET_UNREAD_NOTIFICATION_SUCCESS:
            return {
                ...omit(state, 'unread_notiication_request', 'unread_notification_error'),
                unread_notifications: action.response
            }
        case notificationsConstants.GET_UNREAD_NOTIFICATION_FAILURE:
            return {
                ...omit(state, 'unread_notiication_request'),
                unread_notification_error: action.response
            }

        default:
            return state;
    }
}
